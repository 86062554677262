import { getClaimsSummaryData, getClaimsNotesData, getTransactionsData, getClaimantsData, getClaimsCoverageSection } from '../../services/Api'

import { FETCH_CLAIMS_SUMMARY, UPLOAD_FILE_TO_STORE, FETCH_CLAIMS_NOTES, FETCH_CLAIMS_COVERAGE_INFO, UPDATE_SELECTED_CLAIMANT, FETCH_CLAIMS_TRANSACTIONS, UPDATE_TAB_INDEX, FETCH_CLAIMS_CLAIMANTS, UPDATE_CLAIMS_TABS, RESET_TABS, UPDATE_CLAIMS_INFO } from '../actionTypes'

export const fetchClaimsSummary = claimNo => async dispatch => {
  const { data } = await getClaimsSummaryData(claimNo)
  dispatch({
    type: FETCH_CLAIMS_SUMMARY,
    payload: {
      claimsSummary: data,
      tabIndex: 0,
      tabsList: [{
        id: 1,
        label: 'SUMMARY',
        key: 'summary'
      },
      {
        id: 2,
        label: 'NOTES',
        key: 'notes'
      },
      {
        id: 3,
        label: 'CLAIMANTS',
        key: 'claimants'
      }]
    }
  })
}

export const uploadFileToStore = (params) => async (dispatch) => {
  dispatch({
    payload: {
      file: params
    },
    type: UPLOAD_FILE_TO_STORE
  })
}

export const fetchCoverageInfo = policyNumber => async dispatch => {
  const { data } = await getClaimsCoverageSection(policyNumber)
  dispatch({
    type: FETCH_CLAIMS_COVERAGE_INFO,
    payload: {
      claimsCoverageInfo: data
    }
  })
}

export const updateClaimsTab = (tabIndex) => async dispatch => {
  dispatch({
    type: UPDATE_CLAIMS_TABS,
    payload: {
      tabIndex,
      tabsList: [{
        id: 1,
        label: 'SUMMARY',
        key: 'summary'
      },
      {
        id: 2,
        label: 'NOTES',
        key: 'notes'
      },
      {
        id: 3,
        label: 'CLAIMANTS',
        key: 'claimants'
      }, 
      {
        id: 3,
        label: 'TRANSACTIONS',
        key: 'transactions'
      }],
      refreshTab: true
    }
  })
}


export const updateActiveTab = tabIndex => async dispatch => {
  dispatch({
    type: UPDATE_TAB_INDEX,
    payload: {
      tabIndex
    }
  })
}

export const fetchClaimsNotes = claimNo => async dispatch => {
  const { data } = await getClaimsNotesData(claimNo)
  dispatch({
    type: FETCH_CLAIMS_NOTES,
    payload: {
      claimsNotes: data
    }
  })
}

export const updateClaimantsNumber = selectedClaimant => async dispatch => {
  dispatch({
    type: UPDATE_SELECTED_CLAIMANT,
    payload: {
      selectedClaimant
    }
  })
}

export const fetchClaimsTransactions = (searchVal, claimNo ) => async dispatch => {
  const { data } = await getTransactionsData(searchVal, claimNo)
  dispatch({
    type: FETCH_CLAIMS_TRANSACTIONS,
    payload: {
      claimsTransactions: data
    }
  })
}

export const fetchClaimsClaimants = claimNo => async dispatch => {
  const { data } = await getClaimantsData(claimNo)
  dispatch({
    type: FETCH_CLAIMS_CLAIMANTS,
    payload: {
      claimsClaimants: data
    }
  })
}

export const resetTabs = () => async dispatch => {
  dispatch({
    type: RESET_TABS,
    payload: {
      refreshTab: false
    }
  })
}

export const updateClaimsInfo = (claimsInfo) => async dispatch => {
  dispatch({
    type: UPDATE_CLAIMS_INFO,
    payload: {
      claimsInfo
    }
  })
}