import React, { memo } from 'react'
import { Layout } from 'antd'
import { ConnectedRouter } from 'connected-react-router'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ClaimantsDetail from './components/Claimants/ClaimantsDetail'
import Details from './components/Details/Details'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import ClaimDetail from './pages/ClaimDetail/ClaimDetail'

import './App.css'
import Home from './pages/Home/Home'

import 'antd/dist/antd.min.css'
import Login from './pages/Login/Login'
import BulkUpload from './components/BulkUpload/BulkUpload'
import PrivateAssetsRoute from './components/PrivateAssetsRoute/PrivateAssetsRoute'

const App = ({ history }) => {
  return (
    <BrowserRouter>
      <ConnectedRouter history={history}>
        <Layout className="cure-main-container">
          <Routes>
            <Route element={<Login />} exact path="/" />
            <Route element={<Login />} exact path="/login" />
            <Route
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
              path="/home"
            />
            <Route
              element={
                <PrivateAssetsRoute>
                  <BulkUpload />
                </PrivateAssetsRoute>
              }
              path="/admin/audit-upload"
            />
            <Route
              element={
                <PrivateRoute>
                  <ClaimDetail />
                </PrivateRoute>
              }
              path="/claims/:id"
            >
              <Route element={<Details />} path="details/:detailsId">
                <Route element={<ClaimantsDetail />} path="claimants/:userId" />
              </Route>
            </Route>
          </Routes>
        </Layout>
      </ConnectedRouter>
    </BrowserRouter>
  )
}

export default memo(
  // eslint-disable-line react/display-name
  App,
  (prevProps, nextProps) => prevProps.history.location.pathname === nextProps.history.location.pathname
)
