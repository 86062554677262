import React from 'react'
import { Table, Typography } from 'antd'
import moment from 'moment'

const { Title } = Typography

const VehicleInfo = ({ termVehicleInfo, selectedTerm, getTermDriversInfo, vehicleInfo, getVehicleInfo, getVehicleCoveragesInfo, searchValue }) => {
  const columns = [
    {
      dataIndex: 'vehnum',
      key: 'vehnum',
      width: 200,
      title: 'Vehicle Number'
    },
    {
      key: 'unit',
      width: 200,
      title: 'Unit',
      render: (text, record) => (
        <a
          href="javascript:void(0);"
          onClick={() => {
            getVehicleCoveragesInfo(searchValue, selectedTerm, record.unit)
            getTermDriversInfo(searchValue, selectedTerm, record.unit)
            getVehicleInfo(searchValue, selectedTerm, record.unit)
          }}
        >
          {record.unit}
        </a>
      )
    },
    {
      render: (text, record) => <span>{record.veheff ? moment(record.veheff, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'veheff',
      width: 200,
      title: 'Vehicle Eff date'
    },
    {
      render: (text, record) => <span>{record.vehexp ? moment(record.vehexp, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'vehexp',
      width: 200,
      title: 'Vehicle Exp Date'
    },
    {
      dataIndex: 'vehicle',
      key: 'vehicle',
      width: 200,
      title: 'Vehicle'
    },
    {
      dataIndex: 'vin',
      key: 'vin',
      width: 200,
      title: 'VIN'
    },
    {
      dataIndex: 'terr',
      key: 'terr',
      width: 200,
      title: 'Territory'
    },
    {
      dataIndex: 'costnewsymbol',
      key: 'costnewsymbol',
      width: 200,
      title: 'Cost New Symbol'
    },
    {
      dataIndex: 'collsym',
      key: 'collsym',
      width: 200,
      title: 'Coll Sym'
    },
    {
      dataIndex: 'compsym',
      key: 'compsym',
      width: 200,
      title: 'Comp Sym'
    },
    {
      dataIndex: 'ratingmethod',
      key: 'ratingmethod',
      width: 200,
      title: 'Rating Method'
    },
    {
      dataIndex: 'classcd',
      key: 'classcd',
      width: 200,
      title: 'Class CD'
    },
    {
      dataIndex: 'use',
      key: 'use',
      width: 200,
      title: 'Use'
    },
    {
      dataIndex: 'milestowork',
      key: 'milestowork',
      width: 350,
      title: 'Miles to Work'
    },
    {
      dataIndex: 'daystowork',
      key: 'daystowork',
      width: 200,
      title: 'Days to Work'
    },
    {
      dataIndex: 'altgarageaddr',
      key: 'altgarageaddr',
      width: 200,
      title: 'Alternate Garage Addr'
    },
    {
      dataIndex: 'city',
      key: 'city',
      width: 200,
      title: 'City'
    },
    {
      dataIndex: 'zip',
      key: 'zip',
      width: 200,
      title: 'Zip'
    },
    {
      dataIndex: 'parking',
      key: 'parking',
      width: 200,
      title: 'Parking'
    },
    {
      dataIndex: 'tier',
      key: 'tier',
      width: 200,
      title: 'Tier'
    },
    {
      dataIndex: 'abs',
      key: 'abs',
      width: 200,
      title: 'Abs'
    },
    {
      dataIndex: 'antitheft',
      key: 'antitheft',
      width: 200,
      title: 'Anti Theft'
    },
    {
      dataIndex: 'passiverest',
      key: 'passiverest',
      width: 200,
      title: 'Passive Rest'
    },
    {
      dataIndex: 'pipmedonly',
      key: 'pipmedonly',
      width: 200,
      title: 'PIP Med only'
    },
    {
      dataIndex: 'tieroverride',
      key: 'tieroverride',
      width: 200,
      title: 'Tier override'
    },
    {
      dataIndex: 'c57_use',
      key: 'c57_use',
      width: 200,
      title: 'Use'
    },
    {
      dataIndex: 'lossfreeyears',
      key: 'lossfreeyears',
      width: 200,
      title: 'Loss free years'
    },
  ]

  const garageColumns = [
    {
      dataIndex: 'street',
      key: 'street',
      width: 200,
      title: 'Street'
    },
    {
      dataIndex: 'city',
      key: 'city',
      width: 100,
      title: 'City'
    },
    {
      dataIndex: 'state',
      key: 'state',
      width: 50,
      title: 'State'
    },
    {
      dataIndex: 'zip',
      key: 'zip',
      width: 50,
      title: 'Zip'
    }
  ]

  return (
    <>
    <Table
      columns={columns}
      scroll={{
        x: 4400
      }}
      pagination={false}
      dataSource={termVehicleInfo}
    />
    < br />
    {
      vehicleInfo && vehicleInfo.ALTERNATE_GARAGE_ADDRESS && vehicleInfo.ALTERNATE_GARAGE_ADDRESS.length > 0 && <><Title level={5} style={{fontWeight: 'bold', textDecoration: 'underline' }}>ALTERNATE  GARAGE  ADDRESS</Title><Table
      columns={garageColumns}
      scroll={{
        x: 400
      }}
      pagination={false}
      dataSource={vehicleInfo.ALTERNATE_GARAGE_ADDRESS}
    /></>
    }
    </>
  )
}

VehicleInfo.propTypes = {}

VehicleInfo.defaultProps = {}

export default VehicleInfo
