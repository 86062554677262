import { UPDATE_SEARCH_VALUE, UPDATE_SEARCH_TYPE } from '../actionTypes'

export const updateSearchValue = searchValue => async dispatch => {
  dispatch({
    type: UPDATE_SEARCH_VALUE,
    payload: {
      searchValue
    }
  })
}

export const updateSearchType = searchType => async dispatch => {
  dispatch({
    type: UPDATE_SEARCH_TYPE,
    payload: {
      searchType
    }
  })
}
