import { uploadFileAssets } from "../../services/Api"
import { UPLOAD_FILE_ASSETS } from "../actionTypes"

export const uploadAssets = (files, type, policyNumber) => async dispatch => {
  const response = await uploadFileAssets(files, type, policyNumber)
  dispatch({
    type: UPLOAD_FILE_ASSETS,
    payload: {
      uploadFile: response.data
    }
  })
}
