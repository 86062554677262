import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Table, Spin } from 'antd'
import { connect } from 'react-redux'
import { fetchClaimsNotes } from '../../store/actions'

import './Notes.css'

const columns = [
  {
    title: 'NOTE DATE',
    dataIndex: 'h53_ndate',
    key: 'h53_ndate',
    width: 200
  },
  {
    title: 'TITLE',
    dataIndex: 'f67_note_title',
    key: 'f67_note_title',
    width: 200
  },
  {
    title: 'NOTE',
    dataIndex: 'f67_note_text',
    key: 'f67_note_text',
    width: 300
  },
  {
    title: 'USER ID',
    dataIndex: 'f58_userid',
    key: 'f58_userid',
    width: 200
  }
]

const antIcon = (
  <LoadingOutlined
    spin
    style={{
      fontSize: 24
    }}
  />
)

const Notes = ({ id, fetchClaimsNotes, claimsNotes }) => {
  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    if (id) {
      fetchClaimsNotes(id)
    }
  }, [id, fetchClaimsNotes])

  useEffect(() => {
    if (claimsNotes) {
      setShowSpinner(false)
    }
  }, [claimsNotes])

  return <>{showSpinner ? <Spin indicator={antIcon} /> : <Table columns={columns} dataSource={claimsNotes} pagination={false} />}</>
}

Notes.propTypes = {}

Notes.defaultProps = {}

const mapStateToProps = ({ home, claims }) => ({
  claimsSummary: claims.claimsSummary,
  tabIndex: claims.tabIndex,
  claimsNotes: claims.claimsNotes
})

const mapDispatchToProps = {
  fetchClaimsNotes
}

export default connect(mapStateToProps, mapDispatchToProps)(Notes)
