import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import claims from './claims/reducers'
import home from './home/reducers'
import search from './search/reducers'
import uploadFiles from './uploadFiles/reducers'

export default history =>
  combineReducers({
    claims,
    home,
    search,
    uploadFiles,
    router: connectRouter(history)
  })
