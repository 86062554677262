import {
  AGENCY_INFO,
  DISPLAY_DRIVER_INCIDENTS,
  DISPLAY_IMAGES_ATTACHMENTS,
  DISPLAY_NOTES_INFO,
  DISPLAY_RECIPIENTS_INFO,
  DISPLAY_TERM_COMMON_INFO,
  DISPLAY_TERM_INSURED_INFO,
  DISPLAY_TRANSACTION_HISTORY_FOR_TERM,
  DISPLAY_VEHICLE_COVERAGES,
  DISPLAY_VEHICLE_INFO,
  FETCH_CLAIMS,
  SELECT_POLICY_CLAIMS,
  SELECT_POLICY_TERM,
  SELECT_TERM_DRIVERS,
  SELECT_TERM_VEHICLES,
  UPDATE_POLICY_TERM_INFO,
  VEHICLE_INTERESTS,
  FETCH_ATTACHMENT_SIGNED_URL,
  DOWNLOAD_HISTORICAL_FILES,
  DISPLAY_IMAGES_ATTACHMENTS_AUDIT,
  FETCH_ATTACHMENT_SIGNED_URL_AUDIT
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CLAIMS:
      return {
        ...state,
        ...action.payload
      }
    case SELECT_POLICY_TERM:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_POLICY_TERM_INFO:
      return {
        ...state,
        ...action.payload
      }
    case DISPLAY_DRIVER_INCIDENTS:
      return {
        ...state,
        ...action.payload
      }
    case DISPLAY_TERM_COMMON_INFO:
      return {
        ...state,
        ...action.payload
      }
    case DISPLAY_TERM_INSURED_INFO:
      return {
        ...state,
        ...action.payload
      }
    case DISPLAY_TRANSACTION_HISTORY_FOR_TERM:
      return {
        ...state,
        ...action.payload
      }
    case DISPLAY_VEHICLE_COVERAGES:
      return {
        ...state,
        ...action.payload
      }
    case DOWNLOAD_HISTORICAL_FILES:
      return {
        ...state,
        ...action.payload
      }
    case SELECT_TERM_DRIVERS:
      return {
        ...state,
        ...action.payload
      }
    case SELECT_TERM_VEHICLES:
      return {
        ...state,
        ...action.payload
      }
    case VEHICLE_INTERESTS:
      return {
        ...state,
        ...action.payload
      }
    case AGENCY_INFO:
      return {
        ...state,
        ...action.payload
      }
    case DISPLAY_NOTES_INFO:
      return {
        ...state,
        ...action.payload
      }
    case DISPLAY_RECIPIENTS_INFO:
      return {
        ...state,
        ...action.payload
      }
    case DISPLAY_VEHICLE_INFO:
      return {
        ...state,
        ...action.payload
      }
    case SELECT_POLICY_CLAIMS:
      return {
        ...state,
        ...action.payload
      }
    case DISPLAY_IMAGES_ATTACHMENTS:
      return {
        ...state,
        ...action.payload
      }
    case DISPLAY_IMAGES_ATTACHMENTS_AUDIT:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_ATTACHMENT_SIGNED_URL_AUDIT:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_ATTACHMENT_SIGNED_URL:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
