import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

const CommonInfo = ({ termCommonInfo }) => {
  const programCode = {
    '001': 'Standard',
    '002': 'Basic 1',
    '003': 'Basic 2',
    '004': 'Standard Med Exp Only'
  }
  const columns = [
    {
      title: 'Policy #',
      dataIndex: 'a00_pnum',
      key: 'a00_pnum',
      width: 200
    },
    {
      title: 'Edition',
      dataIndex: 'a06_edition',
      key: 'a06_edition',
      width: 200
    },
    {
      key: 'a08_fdate',
      width: 200,
      title: 'Effective Date',
      render: (text, record) => <span>{record.a08_fdate ? moment(record.a08_fdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>
    },

    {
      title: 'Expiration Date',
      render: (text, record) => <span>{record.a09_xdate ? moment(record.a09_xdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'a09_xdate',
      width: 200
    },
    {
      title: 'Rating State',
      dataIndex: 'a02_rating_state',
      key: 'a02_rating_state',
      width: 200
    },

    {
      key: 'g78_program_code',
      width: 200,
      title: 'Program Code',
      render: (text, record) => <span>{programCode[record.g78_program_code]}</span>
    },
    {
      title: 'Orig Eff Date',
      key: 'a29_orig_eff_date',
      width: 200,
      render: (text, record) => <span>{record.a29_orig_eff_date ? moment(record.a29_orig_eff_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>
    },
    {
      title: 'Tort Indicator',
      dataIndex: 's40_tort_indicator',
      key: 's40_tort_indicator',
      width: 200
    },
    {
      title: 'PIP Health Option',
      dataIndex: 'z20_pip_health_option',
      key: 'z20_pip_health_option',
      width: 200
    },
    {
      title: 'Uni Stack Ind',
      dataIndex: 'n62_uni_stack_ind',
      key: 'n62_uni_stack_ind',
      width: 200
    },
    {
      title: 'Unm Stack Ind',
      dataIndex: 'n62_unm_stack_ind',
      key: 'n62_unm_stack_ind',
      width: 200
    },
    {
      title: 'Good Driver Discount Pct',
      dataIndex: 'good_driver_discount_pct',
      key: 'good_driver_discount_pct',
      width: 200
    }
  ]

  return (
    <Table
      columns={columns}
      scroll={{
        x: 2500
      }}
      pagination={false}
      dataSource={termCommonInfo}
    />
  )
}

CommonInfo.propTypes = {}

CommonInfo.defaultProps = {}

export default CommonInfo
