import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

const DriversInfo = ({ searchValue, termDriverInfo, getDriverIncidentsInfo, selectedTerm }) => {
  
  const columns = [
    {
      title: 'Driver Number',
      dataIndex: 'c74_driver_num',
      key: 'c74_driver_num',
      width: 200,
      render: (text, record) => <a href="javascript:void(0);" onClick={() => {
        getDriverIncidentsInfo(searchValue, selectedTerm, record.c74_driver_num)
      }}>{record.c74_driver_num}</a>
    },
    {
      title: 'FDate',
      render: (text, record) => <span>{record.a29_fdate ? moment(record.a29_fdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
        key: 'a29_fdate',
        width: 200
      },
        {
        title: 'New Date',
        render: (text, record) => <span>{record.a30_xdate ? moment(record.a30_xdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
        key: 'a30_xdate',
        width: 200
      },
        {
        title: 'Name',
        dataIndex: 'upper(b.b27_name1)',
        key: 'upper(b.b27_name1)',
        width: 200
      },
        {
        title: 'Relation',
        dataIndex: 'q77_relation',
        key: 'q77_relation',
        width: 200
      },
        {
        title: 'Birth Date',
        render: (text, record) => <span>{record.b41_birthdate ? moment(record.b41_birthdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
        key: 'b41_birthdate',
        width: 200
      },
        {
        title: 'Gender',
        dataIndex: 'b45_gender',
        key: 'b45_gender',
        width: 200
      },
        {
        title: 'Marital Code',
        dataIndex: 'b44_marital_code',
        key: 'b44_marital_code',
        width: 200
      },
        {
        title: 'Driver License',
        dataIndex: 'b49_driver_license',
        key: 'b49_driver_license',
        width: 200
      },
        {
        title: 'Lic. State',
        dataIndex: 'b50_lic_state',
        key: 'b50_lic_state',
        width: 200
      },
      {
      title: 'Date Licensed',
      render: (text, record) => <span>{record.b51_date_licensed ? moment(record.b51_date_licensed, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'b51_date_licensed',
      width: 200
    },
      {
      title: 'Def. Driver',
      dataIndex: 'w28_def_driver',
      key: 'w28_def_driver',
      width: 200
    },
      {
      title: 'Def. Dr Date',
      render: (text, record) => <span>{record.b60_def_dr_date ? moment(record.b60_def_dr_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'b60_def_dr_date',
      width: 200
    },
      {
      title: 'Good Student',
      dataIndex: 'b52_good_student',
      key: 'b52_good_student',
      width: 200
    },
      {
      title: 'School Away',
      dataIndex: 'b53_school_away',
      key: 'b53_school_away',
      width: 200
    },
      {
      title: 'Drv Training',
      dataIndex: 'b48_drv_training',
      key: 'b48_drv_training',
      width: 200
    },
    {
    title: 'Drv Trn Date',
    render: (text, record) => <span>{record.b48_drv_trn_date ? moment(record.b48_drv_trn_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
    key: 'b48_drv_trn_date',
    width: 200
  },
    {
    title: 'Occupation',
    dataIndex: 'b39_occupation',
    key: 'b39_occupation',
    width: 200
  }
  ]

  return <Table
  columns={columns} 
  scroll={{
    x: 2800,
  }}
  pagination={false}
  dataSource={termDriverInfo}
  />
}

DriversInfo.propTypes = {}

DriversInfo.defaultProps = {}

export default DriversInfo
