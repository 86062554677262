import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Card, Table, Spin, Divider, Row, Col, Typography } from 'antd'
import { connect } from 'react-redux'
import { fetchClaimsClaimants, updateClaimsTab, updateClaimantsNumber } from '../../store/actions'
import { formatPhoneNumber } from '../../utils/StringUtils'

const { Title, Text } = Typography

const antIcon = (
  <LoadingOutlined
    spin
    style={{
      fontSize: 24
    }}
  />
)
const InsuredInfo = ({ termInsuredInfo }) => {
  
  const columns = [
    {
      dataIndex: 'insured',
      key: 'insured',
      width: 200,
      title: 'Insured Name'
      },
    {
      dataIndex: 'b28_email_addr',
      key: 'b28_email_addr',
      width: 200,
      title: 'Email Address'
      }, {
        dataIndex: 'street',
        key: 'street',
        width: 200,
        title: 'Street'
        },  {
      dataIndex: 'city',
      key: 'city',
      width: 200,
      title: 'City'
      }, {
        dataIndex: 'state',
        key: 'state',
        width: 200,
        title: 'State'
        }, {
          dataIndex: 'zip',
          key: 'zip',
          width: 200,
          title: 'Zip'
          },
      {
        render: (text, record) => <span>{record.h_phone ? formatPhoneNumber(record.h_phone): ''}</span>,
      key: 'h_phone',
      width: 200,
      title: 'Home Phone'
      },
      {
        render: (text, record) => <span>{record.m_phone ? formatPhoneNumber(record.m_phone) : ''}</span>,
      key: 'm_phone',
      width: 200,
      title: 'Mobile Phone'
      },{
        render: (text, record) => <span>{record.w_phone ? formatPhoneNumber(record.w_phone) : ''}</span>,
      key: 'w_phone',
      width: 200,
      title: 'Work Phone'
      }
  ]

  return <Table
  columns={columns} 
  scroll={{
    x: 2500,
  }}
  pagination={false}
  dataSource={termInsuredInfo}
  />
}

InsuredInfo.propTypes = {}

InsuredInfo.defaultProps = {}

export default InsuredInfo
