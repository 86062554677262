import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import './Claimants.css'
import { Card, Table, Spin, Divider, Row, Col, Typography } from 'antd'
import { connect } from 'react-redux'
import { fetchClaimsClaimants, updateClaimsTab, updateClaimantsNumber } from '../../store/actions'

const { Title, Text } = Typography

const antIcon = (
  <LoadingOutlined
    spin
    style={{
      fontSize: 24
    }}
  />
)
const Claimants = ({ id, fetchClaimsClaimants, claimsClaimants, tabIndex, updateClaimsTab, updateClaimantsNumber }) => {
  const [data, setData] = useState([])
  useEffect(() => {
    if (id) {
      fetchClaimsClaimants(id)
    }
  }, [fetchClaimsClaimants, id])

  const columns = [
    {
      title: 'STATUS',
      dataIndex: 'claim_status',
      key: 'claim_status'
    },
    {
      title: 'CLAIMANT',
      dataIndex: 'claimant',
      key: 'claimant',
      render: (text, record) => <a href="javascript:void(0);" onClick={() => {
        updateClaimsTab(tabIndex);
        updateClaimantsNumber(record.u10_clmnum)
      }}>{text}</a>
    },
    {
      title: 'COVERAGE',
      dataIndex: 'c87_description',
      key: 'c87_description',
      width: 200
    },
    {
      title: 'LOSS TYPE',
      dataIndex: 'd60_type_of_loss',
      key: 'd60_type_of_loss'
    },
    Table.EXPAND_COLUMN,
  ]

  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    if (claimsClaimants) {
      setShowSpinner(false)
      const data = claimsClaimants.map((record, index) => ({
        ...record,
        key: index
      }))
      setData(data)
    }
  }, [claimsClaimants])

  return <>{showSpinner ? <Spin indicator={antIcon} /> : <Table
  columns={columns} 
  pagination={false}
    expandable={{
      expandedRowRender: (record) => {
        
        return (
          <><table id="cgi-sub-table">
            <tr>
              <th colspan="4">CURRENT RESERVE</th>
              <th colspan="4">PAID TO DATE</th>
            </tr>
            <tr>
              <th>DI LOSS</th>
              <th>DI EXP</th>
              <th>DI LOSS SUB</th>
              <th>DI LOSS SAL</th>
              <th>DI LOSS PAID</th>
              <th>DI EXP PAID</th>
              <th>DI LOSS SUB RC</th>
              <th>DI LOSS SAL RC</th>
            </tr>
            <tr>
              <td>{record.e93_di_loss}</td>
              <td>{record.e99_di_exp}</td>
              <td>{record.f02_di_loss_sub}</td>
              <td>{record.f00_di_loss_sal}</td>
              <td>{record.f04_di_loss_paid}</td>
              <td>{record.f09_di_exp_paid}</td>
              <td>{record.f11_di_loss_sub_rc}</td>
              <td>{record.f10_di_loss_sal_rc}</td>
            </tr>
          </table>
          <Card bordered={false}>
              <Row style={{ marginTop: 15 }}>
                <Col span={24}>
                  <Row>
                    <Col span={11}>
                      <Row>
                        <Title level={5}>CLAIMANT INFORMATION</Title>
                        <Divider dashed style={{ borderColor: '#000' }} />
                      </Row>
                      <Row>
                          <Col span={8}>
                            <Text className="subtext text">Address:</Text>
                          </Col>
                          <Col span={16}>
                            {record.nclmb28addr1 && <Text className="subtext text">{record.nclmb28addr1}</Text>}
                            {record.nclmb30city && <><br />
                            <Text className="subtext text">
                              {record.nclmb30city}, {record.nclmb31state} {record.nclmb32zip}
                            </Text></>}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <Text className="subtext text">Work phone:</Text>
                          </Col>
                          <Col span={16}>
                            <Text className="subtext text">{record.t50_workphone}</Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <Text className="subtext text">Home phone:</Text>
                          </Col>
                          <Col span={16}>
                            <Text className="subtext text">{record.n11_homephone}</Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <Text className="subtext text">Mobile phone:</Text>
                          </Col>
                          <Col span={16}>
                            <Text className="subtext text">{record.mobile_phone}</Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <Text className="subtext text">E-mail Address: </Text>
                          </Col>
                          <Col span={16}>
                            <Text className="subtext text">{record.b28_email_addr}</Text>
                          </Col>
                        </Row>
                    </Col>
                    <Col span={10} offset={1}>
                      <Row>
                        <Title level={5}>PERSONAL INFORMATION</Title>
                        <Divider dashed style={{ borderColor: '#000' }} />
                      </Row>
                      <Row>
                          <Col span={8}>
                            <Text className="subtext text">Gender:</Text>
                          </Col>
                          <Col span={16}>
                            <Text className="subtext text">{record.gender}</Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <Text className="subtext text">DOB:</Text>
                          </Col>
                          <Col span={16}>
                            <Text className="subtext text">{record.dob}</Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <Text className="subtext text">Marital Status:</Text>
                          </Col>
                          <Col span={16}>
                            <Text className="subtext text">{record.marital_status}</Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <Text className="subtext text">SSN/FEIN: </Text>
                          </Col>
                          <Col span={16}>
                            <Text className="subtext text"></Text>
                          </Col>
                        </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </>
        )
      },
      rowExpandable: (record) => record.name !== 'Not Expandable',
    }}
    dataSource={data}
  />}</>
}

Claimants.propTypes = {}

Claimants.defaultProps = {}

const mapStateToProps = ({ claims }) => ({
  claimsClaimants: claims.claimsClaimants,
  tabIndex: claims.tabIndex
})

const mapDispatchToProps = {
  fetchClaimsClaimants,
  updateClaimsTab,
  updateClaimantsNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(Claimants)
