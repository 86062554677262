import React from 'react'
import { Table } from 'antd'

const AgencyInfo = ({ agencyInfo }) => {

  const columns = [
    {
      title: 'Agency #',
      dataIndex: 'a04_anum',
      key: 'a04_anum',
      width: 200
    },
  {
      title: 'Agency Name',
      dataIndex: 'agency',
      key: 'agency',
      width: 200
    },
  {
      title: 'Street Address',
      dataIndex: 'street',
      key: 'street',
      width: 200
    },
  {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 200
    },
  {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 200
    },
  {
      title: 'Zip',
      dataIndex: 'zip',
      key: 'zip',
      width: 200
    },
  {
      title: 'Home Phone',
      dataIndex: 'h_phone',
      key: 'h_phone',
      width: 200
    },
  {
      title: 'Mobile Phone #',
      dataIndex: 'b_phone',
      key: 'b_phone',
      width: 200
    },
  {
      title: 'Work Phone',
      dataIndex: 'w_phone',
      key: 'w_phone',
      width: 200
    },
  {
    title: 'Email',
    dataIndex: 'b28_email_addr',
    key: 'b28_email_addr',  
    width: 200
  },
  ]

  return <Table
  columns={columns} 
  scroll={{
    x: 2500,
  }}
  pagination={false}
  dataSource={agencyInfo}
  />
}

AgencyInfo.propTypes = {}

AgencyInfo.defaultProps = {}

export default AgencyInfo
