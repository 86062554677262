import React, { useCallback, useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Row, Typography, Tabs, Spin } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import './ClaimDetail.css'
import Claimants from '../../components/Claimants/Claimants'
import Notes from '../../components/Notes/Notes'
import Summary from '../../components/Summary/Summary'
import Transactions from '../../components/Transactions/Transactions'
import { fetchClaimsSummary, updateActiveTab, resetTabs } from '../../store/actions'

const { Title, Text } = Typography
const antIcon = (
  <LoadingOutlined
    spin
    style={{
      fontSize: 24
    }}
  />
)
const ClaimDetail = ({ fetchClaimsSummary, claimsSummary, tabIndex, updateActiveTab, tabsList, refreshTab, resetTabs, claimantNumber, claimsInfo }) => {
  const { id } = useParams()
  const [claimsData, setClaimsData] = useState({})
  const [showSpinner, setShowSpinner] = useState(false)
  const [tabs, setTabs] = useState([])
  const navigate = useNavigate()
  
  const getComponent = useCallback((key, id) => {
    if (key === 'summary') {
      return <Summary id={id} />
    } else if (key === 'notes') {
      return <Notes id={id} />
    } else if (key === 'transactions') {
      return <Transactions id={id} />
    } else {
      return <Claimants id={id} />
    }
  }, [])

  const updateTab = useCallback(tabIndex => {
    updateActiveTab(tabIndex)
    // setSelectedTab(index);history(`/claims/${id}/details/${index}`)
  }, [updateActiveTab])

  useEffect(() => {
    if (id) {
      fetchClaimsSummary(id)
      setShowSpinner(true)
    }
  }, [id, fetchClaimsSummary])

  useEffect(() => {
    if (!claimsInfo) {
      navigate('/home')
    }
  }, [claimsInfo, navigate])

  useEffect(() => {
    if (claimantNumber) {
      updateTab(3)
    }
  }, [claimantNumber, updateTab])

  useEffect(() => {
    if (claimsSummary && claimsSummary.length > 0) {
      const [claimsData] = claimsSummary
      setShowSpinner(false)
      setClaimsData(claimsData)
    }
  }, [claimsSummary])

  useEffect(() => {
    if ((tabs && tabs.length === 0 && tabsList && tabsList.length > 0) || (refreshTab)) {
      const list = tabsList.map(({ id: tabKey, label, key }) => ({
        id: tabKey,
        label,
        key,
        children: getComponent(key, id)
      }))
      setTabs(list)
      resetTabs()
    }
  }, [tabsList, tabs, id, getComponent, resetTabs, refreshTab])

  // const tabs = [
  //   {
  //     id: 1,
  //     label: 'SUMMARY',
  //     key: 'summary',
  //     children: <Summary claimsData={claimsData} id={id} />
  //   },
  //   {
  //     id: 2,
  //     label: 'NOTES',
  //     key: 'notes',
  //     children: <Notes id={id} />
  //   },
  //   {
  //     id: 3,
  //     label: 'TRANSACTIONS',
  //     key: 'transactions',
  //     children: <Transactions id={id} />
  //   },
  //   {
  //     id: 4,
  //     label: 'CLAIMANTS',
  //     key: 'claimants',
  //     children: <Claimants id={id} />
  //   }
  // ]

  return (
    <>
      <Row className="page-content bg-white">
        <>
          {showSpinner
            ? (
            <Spin indicator={antIcon} />
              )
            : (
            <Card bordered={false} className="input-card">
              <Row>
                <Title className="title" level={1}>
                  CLAIM# {id}
                </Title>
              </Row>
              <Row>
                <Text className="subtext">Policy#</Text>
                <Text className="subtext-value">{claimsInfo?.policyNumber}</Text>|<Text className="subtext">Loss Date:</Text>
                <Text className="subtext-value">
                  {claimsInfo?.lossDate ? moment(claimsInfo?.lossDate, 'YYYY-MM-DD').format('MM-DD-YYYY') : ''}
                </Text>
                |<Text className="subtext">Report Date:</Text>
                <Text className="subtext-value">
                  {claimsData.d43_reported_date ? moment(claimsData.d43_reported_date, 'YYYY-MM-DD').format('MM-DD-YYYY') : ''}
                </Text>
              </Row>
              <Row>
                <Text className="subtext">Insured:</Text>
                <Text className="subtext-value">{claimsData.b27_name1}</Text>|<Text className="subtext">Address:</Text>
                <Text className="subtext-value">
                  {claimsData.b28_addr1}, {claimsData.b30_city}, {claimsData.b31_state} {claimsData.b32_zip}
                </Text>
              </Row>
              <Row style={{ marginTop: 15 }}>
                <Tabs
                  activeKey={tabIndex}
                  defaultActiveKey={0}
                  items={tabs.map((tab, index) => {
                    return {
                      label: tab.label,
                      key: index,
                      children: tab.children
                    }
                  })}
                  onChange={index => updateTab(index)}
                  type="card"
                />
              </Row>
            </Card>
              )}
        </>
      </Row>
    </>
  )
}

const mapStateToProps = ({ home, claims }) => ({
  claimsInfo: claims.claimsInfo,
  claimsSummary: claims.claimsSummary,
  refreshTab: claims.refreshTab,
  tabIndex: claims.tabIndex,
  tabsList: claims.tabsList,
  claimantNumber: claims.selectedClaimant
})

const mapDispatchToProps = {
  fetchClaimsSummary,
  updateActiveTab,
  resetTabs
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimDetail)
