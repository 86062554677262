import {
  getClaimsSearch,
  getDisplayAgencyInfo,
  getDisplayNotesData,
  getDisplayRecipientsData,
  getDisplayTermCommonInfo,
  getDisplayTermInsuredInfo,
  getDisplayTransactionHistoryForTerm,
  getDisplayVehicleCoverages,
  getDisplayVehicleInfo,
  getDisplayVehicleInterests,
  getDriverIncidents,
  getLastNameSearch,
  getSelectPolicyTerm,
  getClaimsByPolicyTerm,
  getTermDrivers,
  getTermVehicles,
  getImagesAttachmentByPolicyNumber,
  getImagesAttachmentAuditByPolicyNumber,
  getDocumentSignedUrl,
  downloadFile,
  getDocumentSignedUrlForAudit
} from '../../services/Api'

import {
  AGENCY_INFO,
  DISPLAY_DRIVER_INCIDENTS,
  DISPLAY_IMAGES_ATTACHMENTS,
  DISPLAY_NOTES_INFO,
  DISPLAY_RECIPIENTS_INFO,
  DISPLAY_TERM_COMMON_INFO,
  DISPLAY_TERM_INSURED_INFO,
  DISPLAY_TRANSACTION_HISTORY_FOR_TERM,
  DISPLAY_VEHICLE_COVERAGES,
  DISPLAY_IMAGES_ATTACHMENTS_AUDIT,
  DISPLAY_VEHICLE_INFO,
  FETCH_CLAIMS,
  SELECT_POLICY_CLAIMS,
  SELECT_POLICY_TERM,
  SELECT_TERM_DRIVERS,
  SELECT_TERM_VEHICLES,
  UPDATE_POLICY_TERM_INFO,
  VEHICLE_INTERESTS,
  FETCH_ATTACHMENT_SIGNED_URL,
  FETCH_ATTACHMENT_SIGNED_URL_AUDIT,
  DOWNLOAD_HISTORICAL_FILES
} from '../actionTypes'

export const fetchClaims = (searchValue, searchType) => async dispatch => {
  let response = {}
  if (searchType === 'claims') {
    response = await getClaimsSearch(searchValue)
  } else if (searchType === 'policy') {
    response = await getClaimsSearch(searchValue)
  } else if (searchType === 'name') {
    response = await getLastNameSearch(searchValue)
  }
  const { data } = response
  dispatch({
    type: FETCH_CLAIMS,
    payload: {
      claimsList: data
    }
  })
}

export const fetchPolicyTerms = searchValue => async dispatch => {
  const response = await getSelectPolicyTerm(searchValue)
  const { data } = response
  dispatch({
    type: SELECT_POLICY_TERM,
    payload: {
      policyTermsList: data
    }
  })
}

export const fetchPolicyClaims = searchValue => async dispatch => {
  const response = await getClaimsByPolicyTerm(searchValue)
  const { data } = response
  dispatch({
    type: SELECT_POLICY_CLAIMS,
    payload: {
      policyClaimsList: data
    }
  })
}

export const updatePolicyTerm = selectedTerm => async dispatch => {
  dispatch({
    type: UPDATE_POLICY_TERM_INFO,
    payload: {
      selectedTerm
    }
  })
}

export const getTermCommonInfo  = (searchValue, edison) => async dispatch => {
  const response = await getDisplayTermCommonInfo(searchValue, edison)
  const { data } = response
  dispatch({
    type: DISPLAY_TERM_COMMON_INFO,
    payload: {
      termCommonInfo: data
    }
  })
}

export const getTermInsuredInfo  = (searchValue, edison) => async dispatch => {
  const response = await getDisplayTermInsuredInfo(searchValue, edison)
  const { data } = response
  dispatch({
    type: DISPLAY_TERM_INSURED_INFO,
    payload: {
      termInsuredInfo: data
    }
  })
}

export const getTermVehiclesInfo  = (searchValue, edison) => async dispatch => {
  const response = await getTermVehicles(searchValue, edison)
  const { data } = response
  const vechiclesList = data.reduce((vechicles, room) => {
    let l = vechicles.filter(r => {
      return r.c59_id !== room.c59_id && r.w48_xdate !== room.w48_xdate;
    });
    if (l.length === 0) {
      return [...vechicles, room]
    }
    return vechicles;
  }, []);

  dispatch({
    type: SELECT_TERM_VEHICLES,
    payload: {
      termVehicleInfo: vechiclesList
    }
  })
}

export const getVehicleCoveragesInfo  = (searchValue, edison, unit) => async dispatch => {
  const response = await getDisplayVehicleCoverages(searchValue, edison, unit)
  const { data } = response
  dispatch({
    type: DISPLAY_VEHICLE_COVERAGES,
    payload: {
      vehicleCoverages: data
    }
  })
}

export const getTermDriversInfo  = (searchValue, edison, unit) => async dispatch => {
  const response = await getTermDrivers(searchValue, edison, unit)
  const { data } = response
  dispatch({
    type: SELECT_TERM_DRIVERS,
    payload: {
      termDriverInfo: data
    }
  })
}

export const getDriverIncidentsInfo  = (searchValue, edison, driverNumber) => async dispatch => {
  const response = await getDriverIncidents(searchValue, edison, driverNumber)
  const { data } = response
  dispatch({
    type: DISPLAY_DRIVER_INCIDENTS,
    payload: {
      driverIncidentInfo: data
    }
  })
}

export const getTransactionHistoryForTerm  = (searchValue, edison) => async dispatch => {
  const response = await getDisplayTransactionHistoryForTerm(searchValue, edison)
  const { data } = response
  if (data.length > 0) {
    const clonedData = [...data]
    const [transactionHistory] = [...data].slice(-1)
    const updatedRow = {
      ...transactionHistory,
      n45_ndate: '<b>Total</b>'
    }
    clonedData[data.length - 1] = updatedRow
    dispatch({
      type: DISPLAY_TRANSACTION_HISTORY_FOR_TERM,
      payload: {
        termTransactionHistory: clonedData
      }
    })
  } else {
    dispatch({
      type: DISPLAY_TRANSACTION_HISTORY_FOR_TERM,
      payload: {
        termTransactionHistory: data
      }
    })
  }
}

export const getVehicleInterests  = (searchValue, edison) => async dispatch => {
  const response = await getDisplayVehicleInterests(searchValue, edison)
  const { data } = response
  dispatch({
    type: VEHICLE_INTERESTS,
    payload: {
      vehicleInterests: data
    }
  })
}

export const getAgencyInfo  = (searchValue, edison) => async dispatch => {
  const response = await getDisplayAgencyInfo(searchValue, edison)
  const { data } = response
  dispatch({
    type: AGENCY_INFO,
    payload: {
      agencyInfo: data
    }
  })
}

export const getPolicyNotesData  = (searchValue) => async dispatch => {
  const response = await getDisplayNotesData(searchValue)
  const { data } = response
  dispatch({
    type: DISPLAY_NOTES_INFO,
    payload: {
      policyNotesInfo: data
    }
  })
}

export const getPolicyRecipientsData  = (searchValue, edison) => async dispatch => {
  const response = await getDisplayRecipientsData(searchValue, edison)
  const { data } = response
  dispatch({
    type: DISPLAY_RECIPIENTS_INFO,
    payload: {
      policyRecipentsInfo: data
    }
  })
}

export const getVehicleInfo  = (searchValue, edison, unit) => async dispatch => {
  const response = await getDisplayVehicleInfo(searchValue, edison, unit)
  const { data } = response
  dispatch({
    type: DISPLAY_VEHICLE_INFO,
    payload: {
      vehicleInfo: data
    }
  })
}

export const fetchImagesAttachmentByPolicyNumber = searchValue => async dispatch => {
  const response = await getImagesAttachmentByPolicyNumber(searchValue)
  const { data } = response
  dispatch({
    type: DISPLAY_IMAGES_ATTACHMENTS,
    payload: {
      imagesAndAttachments: data
    }
  })
}

export const fetchImagesAttachmentAuditByPolicyNumber = (searchType, searchValue) => async dispatch => {
  const type = searchType === 'claims' ? "C" : "P"
  const response = await getImagesAttachmentAuditByPolicyNumber(type, searchValue)
  const { data } = response
  dispatch({
    type: DISPLAY_IMAGES_ATTACHMENTS_AUDIT,
    payload: {
      imagesAndAttachmentsAudit: data
    }
  })
}

export const fetchDocumentSignedUrl = (bucketUrl, fileName) => async dispatch => {
  const response = await getDocumentSignedUrl(bucketUrl, fileName)
  const { data } = response
  dispatch({
    type: FETCH_ATTACHMENT_SIGNED_URL,
    payload: {
      signedUrl: data
    }
  })
}
export const downloadFilesFromServer = (number) => async dispatch => {
  const response = await downloadFile(number)
  dispatch({
    type: DOWNLOAD_HISTORICAL_FILES,
    payload: {
      downloadFile: response
    }
  })
}

export const fetchDocumentSignedUrlForAudit = (bucketUrl, fileName) => async dispatch => {
  const response = await getDocumentSignedUrlForAudit(bucketUrl, fileName)
  const { data } = response
  dispatch({
    type: FETCH_ATTACHMENT_SIGNED_URL_AUDIT,
    payload: {
      signedUrl: data
    }
  })
}
