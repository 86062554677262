import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import moment from 'moment'
import { FileIcon, defaultStyles } from 'react-file-icon'
import './ImagesInfoAudit.css'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'

import {
  fetchDocumentSignedUrlForAudit
} from '../../store/actions'

const ImagesAndAttachmentsAudit = ({ imagesAttInfo, fetchDocumentSignedUrlForAudit, signedUrl }) => {

  const [fileName, setFileName] = useState('')

  const download = (dataurl, filename) => {
    saveAs(dataurl, filename)
    setFileName('')
  }

  useEffect(() => {
    if (signedUrl && fileName) {
      download(signedUrl, fileName)
    }
  }, [signedUrl, fileName])

  const handleClick = record => {
    const { ftpFilePath, filename } = record
    setFileName(filename)
    fetchDocumentSignedUrlForAudit(ftpFilePath, filename)
  }

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => {
        const fileExtenstion = /[^.]+$/.exec(record.filename)
        return (
          <div className="icon">
            <FileIcon extension={fileExtenstion} {...defaultStyles[fileExtenstion]} />
          </div>
        )
      },
      width: 80
    },
    {
      title: 'Name',
      dataIndex: 'fileDescription',
      key: 'fileDescription',
      render: (text, record) => <a onClick={() => handleClick(record)}>{record.fileDescription}</a>,
      width: 200
    },
    {
      title: 'DateTime',
      render: (text, record) => (
        <span>{record.createdAt ? moment(record.createdAt, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss') : ''}</span>
      ),
      key: 'createdAt',
      width: 200
    },
    {
      title: 'User ID',
      render: (text, record) => (
        <span>AUDIT</span>
      ),
      key: 'creator',
      width: 100
    }
  ]

  return (
    <Table
      columns={columns}
      scroll={{
        x: 1000
      }}
      pagination={false}
      dataSource={imagesAttInfo}
    />
  )
}

ImagesAndAttachmentsAudit.propTypes = {}

ImagesAndAttachmentsAudit.defaultProps = {}

const mapStateToProps = ({ home }) => {
  return {
    signedUrl: home.signedUrl
  }
}

const mapDispatchToProps = {
  fetchDocumentSignedUrlForAudit
}

export default connect(mapStateToProps, mapDispatchToProps)(ImagesAndAttachmentsAudit)

