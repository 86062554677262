import { UPDATE_SEARCH_VALUE, UPDATE_SEARCH_TYPE } from '../actionTypes'

const initialState = {
  searchType: 'claims'
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SEARCH_VALUE:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_SEARCH_TYPE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
