import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

const IncidentsInfo = ({ driverIncidentInfo }) => {
  
  const columns = [
    {
      title: 'Driver Number',
      dataIndex: 'c74_driver_num',
      key: 'c74_driver_num',
      width: 200
    },
    {
      title: 'Inc. Source',
        dataIndex: 'a5b_inc_source',
        key: 'a5b_inc_source',
        width: 200
      },
        {
        title: 'Claim No.',
        dataIndex: 'u10_claim_no',
        key: 'u10_claim_no',
        width: 200
      },
        {
        title: 'Acc Viol Ind',
        dataIndex: 'a5f_acc_viol_ind',
        key: 'a5f_acc_viol_ind',
        width: 200
      },
        {
        title: 'Vioc Code',
        dataIndex: 'b65_viol_code',
        key: 'b65_viol_code',
        width: 200
      },
        {
        title: 'Incident Date',
        render: (text, record) => <span>{record.b64_incident_date ? moment(record.b64_incident_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
        key: 'b64_incident_date',
        width: 200
      },
        {
        title: 'Conviction Dt',
        render: (text, record) => <span>{record.b64_conviction_dt ? moment(record.b64_conviction_dt, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
        key: 'b64_conviction_dt',
        width: 200
      },
        {
        title: 'Threshold Met Date',
        render: (text, record) => <span>{record.threshold_met_date ? moment(record.threshold_met_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
        key: 'threshold_met_date',
        width: 200
      },
        {
        title: 'Incident Pts',
        dataIndex: 'b68_incident_pts',
        key: 'b68_incident_pts',
        width: 200
      },
        {
        title: 'Security Code',
        dataIndex: 'severity_code',
        key: 'severity_code',
        width: 200
      },
      {
      title: 'Fault',
      dataIndex: 'b65_at_fault',
      key: 'b65_at_fault',
      width: 200
    },
      {
      title: 'Forgiven',
      dataIndex: 'b72_forgiven',
      key: 'b72_forgiven',
      width: 200
    },
      {
      title: 'Chargeable',
      dataIndex: 'b72_chargeable',
      key: 'b72_chargeable',
      width: 200
    },
      {
      title: 'Amt Prop Damage',
      dataIndex: 'a5g_amt_prop_dam',
      key: 'a5g_amt_prop_dam',
      width: 200
    },
    {
    title: 'Bodily Injured',
    dataIndex: 'a5c_bodily_inj',
    key: 'a5c_bodily_inj',
    width: 200
  }
  ]

  return <Table
  columns={columns} 
  scroll={{
    x: 2500,
  }}
  pagination={false}
  dataSource={driverIncidentInfo}
  />
}

IncidentsInfo.propTypes = {}

IncidentsInfo.defaultProps = {}

export default IncidentsInfo
