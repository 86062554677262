import React, { useState } from 'react'
import { Button, Card, Input, Row, Space } from 'antd'

import './Login.css'

const Login = () => {
  const [password, setPassword] = useState('')

  const handleSubmit = () => {
    if (password === 'Cure2022!' || password === 'Cure2023@!$') {
      localStorage.setItem('cgi-key', JSON.stringify(password))
      localStorage.removeItem('cgi-key-upload')
      window.location = '/home'
    } else if (password === 'CureUpload2023!') {
      localStorage.setItem('cgi-key-upload', JSON.stringify(password))
      localStorage.removeItem('cgi-key')
      window.location = '/admin/audit-upload'
    }
  }

  return (
    <>
      <Row className="page-content bg-white">
        <Card bordered={false} className="input-card" style={{ textAlign: 'center' }}>
          <img
            alt="logo"
            height="auto"
            src="https://assets.cure.com/img/main-site/LogoBlue-2022.png"
            style={{ maxWidth: 550, minWidth: 150 }}
            width="100%"
          />
          <Row style={{ marginTop: '25vh', textAlign: 'center', display: 'block' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Input disabled placeholder="User name" size="large" style={{ maxWidth: 550, minWidth: 150 }} value="CGI" />
              <Input.Password
                onChange={event => setPassword(event.target.value)}
                placeholder="Password"
                size="large"
                style={{ maxWidth: 550, minWidth: 150 }}
                value={password}
              />
              <Button className="submit-btn" onClick={handleSubmit} style={{ marginTop: 18, height: 36, width: 200 }} type="primary">
                Submit
              </Button>
            </Space>
          </Row>
        </Card>
      </Row>
    </>
  )
}

export default Login
