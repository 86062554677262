import axios from 'axios'

const { REACT_APP_API_URL } = process.env

export const getHistoricalData = async id => {
  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/historical`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({ cNo: id })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getClaimsSummaryData = async id => {
  const data = new FormData()
  data.append('searchValue', id)

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/claimsSummary`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getClaimsCoverageSection = async policyNumber => {
  const data = new FormData()
  data.append('policyNumber', policyNumber)

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/coveragesection`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getClaimsNotesData = async id => {
  const data = new FormData()
  data.append('searchValue', id)

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/notes`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getTransactionsData = async (id, claimantNum) => {
  const data = new FormData()
  data.append('searchValue', id)
  data.append('p1', claimantNum);
  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/transactions`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getClaimantsData = async id => {
  const data = new FormData()
  data.append('searchValue', id)

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/claimants`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getClaimsSearch = async searchValue => {
  const data = new FormData()
  data.append('searchValue', searchValue)

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/searchBy/claimNumber`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getLastNameSearch = async searchValue => {
  const data = new FormData()
  data.append('searchValue', searchValue)

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/searchBy/lastName`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getSelectPolicyTerm  = async searchValue => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/searchpolicyterm/?policyNumber=${searchValue}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getClaimsByPolicyTerm  = async searchValue => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/searchClaimsByPolicyTerm/?policyNumber=${searchValue}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getDisplayTermCommonInfo  = async (searchValue, edison) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/displaytermcommoninfo/?policyNumber=${searchValue}&edition=${edison}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getDisplayTermInsuredInfo  = async (searchValue, edison) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/displayterminsuredinfo/?policyNumber=${searchValue}&edition=${edison}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getTermVehicles  = async (searchValue, edison) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/selecttermvehicles/?policyNumber=${searchValue}&edition=${edison}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getDisplayVehicleCoverages  = async (searchValue, edison, unit) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/displayvehiclecoverages/?policyNumber=${searchValue}&edition=${edison}&unit=${unit}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getTermDrivers  = async (searchValue, edison, unit) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/selecttermdrivers/?policyNumber=${searchValue}&edition=${edison}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getDriverIncidents  = async (searchValue, edison, driverNumber) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/displaydriverincidents/?policyNumber=${searchValue}&edition=${edison}&driverNumber=${driverNumber}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}


export const getDisplayTransactionHistoryForTerm  = async (searchValue, edison, driverNumber) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/displaytransactionhistoryforterm/?policyNumber=${searchValue}&edition=${edison}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getDisplayVehicleInterests  = async (searchValue, edison) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/displayvehicleinterest/?policyNumber=${searchValue}&edition=${edison}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getDisplayAgencyInfo = async (searchValue, edison) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/displayagencyinfo/?policyNumber=${searchValue}&edition=${edison}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}


export const getDisplayNotesData = async (searchValue) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/displaynotestab/?policyNumber=${searchValue}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}



export const getDisplayRecipientsData = async (searchValue, edison) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/displayreceiptstab/?policyNumber=${searchValue}&edition=${edison}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getDisplayVehicleInfo  = async (searchValue, edison, unit) => {
  const data = '';

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/displayvehicleinfo/?policyNumber=${searchValue}&edition=${edison}&unit=${unit}`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}


export const getImagesAttachmentByPolicyNumber = async searchValue => {
  const data = new FormData()
  data.append('policyNumber', searchValue)

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/imagesAttachmentByPolicyNumber`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getImagesAttachmentAuditByPolicyNumber = async (searchType, searchValue) => {
  const data = new FormData()
  data.append('type', searchType);
  data.append('number', searchValue)

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/cloud/imagesAttachmentByNumberAndType`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getDocumentSignedUrl = async (bucketUrl, fileName) => {
  const data = new FormData()
  data.append('path', bucketUrl);
  data.append('fileName', fileName);
  
  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/cloud/getsignedurl`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getDocumentSignedUrlForAudit = async (bucketUrl, fileName) => {
  const data = new FormData()
  data.append('path', bucketUrl);
  data.append('fileName', fileName);
  
  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/cloud/getsignedurlforaudit`,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const downloadFile = async (number) => {
  const data = new FormData()
  data.append('number', number);
  
  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/cloud/downloadHistoricalFiles`,
    data,
    responseType: 'blob'
  }

  let blobResponse
  try {
    blobResponse = await axios(config)    
  } catch (error) {
    console.log(error)
  }
  return blobResponse
}

export const uploadFileAssets = async (files, type, number) => {
  const data = new FormData()
  
  // files.map((file) => {
  //   data.append('files', file);
  // })

  // for(let key of Object.keys(files)) {
  //   if (key !== 'length') {
      // data.append('files', files);
  //   }
  // }

  const formData = new FormData();

  for (const key of Object.keys(files)) {
      data.append('files', files[key]);
  }
  data.append('type', type);
  data.append('number', number);




  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/cloud/uploadFiles`,
    data,
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}
