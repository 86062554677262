import React, { useEffect, useState } from 'react'
import { Card, Row, Select, Input, Typography, Table, Spin, Collapse, Button } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons';

import './Home.css'
import moment from 'moment'
import { connect } from 'react-redux'
import {
  fetchClaims,
  fetchImagesAttachmentByPolicyNumber,
  fetchImagesAttachmentAuditByPolicyNumber,
  fetchPolicyClaims,
  fetchPolicyTerms,
  getAgencyInfo,
  getDriverIncidentsInfo,
  getPolicyNotesData,
  getPolicyRecipientsData,
  getTermCommonInfo,
  getTermDriversInfo,
  getTermInsuredInfo,
  getTermVehiclesInfo,
  getTransactionHistoryForTerm,
  getVehicleCoveragesInfo,
  getVehicleInfo,
  getVehicleInterests,
  updateClaimsInfo,
  updatePolicyTerm,
  updateSearchType,
  updateSearchValue,
  downloadFilesFromServer
} from '../../store/actions'
import { LoadingOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import PolicyStructure from '../../components/PolicyStructure/PolicyStructure'
import ImagesAttachmentsInfo from '../../components/ImagesAttachmentsInfo/ImagesAttachmentsInfo'
import ImagesAndAttachmentsAudit from '../../components/ImagesInfoAudit/ImagesInfoAudit'

const { Search } = Input
const { Option } = Select
const { Panel } = Collapse

const { Title, Text } = Typography
const antIcon = (
  <LoadingOutlined
    spin
    style={{
      fontSize: 24
    }}
  />
)
const Home = ({
  fetchClaims,
  claimsList,
  route = {},
  selectedTerm,
  getAgencyInfo,
  updateSearchValue,
  updateClaimsInfo,
  searchType,
  updatePolicyTerm,
  policyTermsList,
  policyClaimsList,
  searchValue,
  getPolicyRecipientsData,
  updateSearchType,
  imagesAndAttachments,
  imagesAndAttachmentsAudit,
  fetchImagesAttachmentByPolicyNumber,
  fetchImagesAttachmentAuditByPolicyNumber,
  fetchPolicyTerms,
  fetchPolicyClaims,
  getTermCommonInfo,
  getTermInsuredInfo,
  agencyInfo,
  getTermVehiclesInfo,
  getPolicyNotesData,
  getVehicleCoveragesInfo,
  policyRecipentsInfo,
  getTermDriversInfo,
  getDriverIncidentsInfo,
  getTransactionHistoryForTerm, 
  getVehicleInterests,
  termCommonInfo,
  termDriverInfo,
  vehicleInterests,
  getVehicleInfo,
  policyNotesInfo,
  vehicleInfo,
  termInsuredInfo,
  termTransactionHistory,
  termVehicleInfo,
  driverIncidentInfo,
  vehicleCoverages,
  downloadFile,
  downloadFilesFromServer
}) => {

  const [showSpinner, setShowSpinner] = useState(false)
  const [showMessage, setShowMessage] = useState('')
  const [showClaimsDataMessage, setShowClaimsDataMessage] = useState('')
  const navigate = useNavigate()
  const [localSearchValue, setLocalSearchValue] = useState(searchValue)
  const [localSearchType, setLocalSearchType] = useState(searchType)
  const [downloadingFile, setDownloadingFile] = useState(false)

  useEffect(() => {
    if (downloadFile) {
      var binaryData = [];
      binaryData.push(downloadFile);
      const bloburl = window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
      const anchor = document.createElement('a');
      anchor.href = bloburl;
      anchor.download = 'download.zip';
      anchor.click()
      setDownloadingFile(false)
    }
  }, [downloadFile])
  const handleClick = (record) => {
    updateClaimsInfo({
      occuranceNo: record.b69_claim_occur,
      policyNumber: record.a00_pnum,
      lossDate: record.b70_loss_date,
      type: record.d74_claim_type
    })
    navigate(`/claims/${record.b69_claim_occur}`)
  }
  const columns = [
    {
      title: 'OCCURRENCE NUMBER',
      dataIndex: 'b69_claim_occur',
      key: 'b69_claim_occur',
      render: (text, record) => <a onClick={() => handleClick(record)}>{text}</a>
    },
    {
      title: 'POLICY NUMBER',
      dataIndex: 'a00_pnum',
      key: 'a00_pnum'
    },
    {
      title: 'DATE OF LOSS',
      dataIndex: 'b70_loss_date',
      key: 'b70_loss_date',
      render: date => moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')
    }
  ]
  
  const updateSearch = value => {
    setShowSpinner(true)
    updateSearchValue(value)
    updateSearchType(localSearchType)
    if (localSearchType === 'policy') {
      fetchPolicyTerms(localSearchValue)
      fetchPolicyClaims(localSearchValue)
      fetchImagesAttachmentByPolicyNumber(localSearchValue)
      fetchImagesAttachmentAuditByPolicyNumber(localSearchType, localSearchValue)
    }
  }

  const localStorageSearchValue = JSON.parse(localStorage.getItem('searchValue'))
  const localStorageSearchType = JSON.parse(localStorage.getItem('searchType'))

  useEffect(() => {
    if (searchValue && localSearchType !== 'policy') {
      setShowSpinner(true)
      fetchClaims(searchValue, searchType)
      fetchImagesAttachmentByPolicyNumber(searchValue)
      fetchImagesAttachmentAuditByPolicyNumber(searchType, searchValue)
    }
  }, [searchValue, fetchClaims, searchType, localSearchType, fetchImagesAttachmentByPolicyNumber, fetchImagesAttachmentAuditByPolicyNumber])

  useEffect(() => {
    if (localStorageSearchValue && localStorageSearchType && localSearchType !== 'policy') {
      setShowSpinner(true)
      localStorage.removeItem('searchValue')
      localStorage.removeItem('searchType')

      fetchClaims(localStorageSearchValue, localStorageSearchType)
      updateSearchValue(localStorageSearchValue)
      updateSearchType(localStorageSearchType)
      setLocalSearchValue(localStorageSearchValue)
      setLocalSearchType(localStorageSearchType)
    }
  }, [localStorageSearchValue, fetchClaims, updateSearchValue, updateSearchType, localStorageSearchType, localSearchType])

  useEffect(() => {
    if (claimsList && claimsList.length > 0 && localSearchType !== 'policy') {
      setShowSpinner(false)
    } else if (claimsList && claimsList.length === 0 && localSearchType !== 'policy') {
      setShowMessage('No data found!')
      setShowSpinner(false)
    }
  }, [claimsList, showSpinner, localSearchType])

  useEffect(() => {
    if (policyTermsList && policyTermsList.length === 0 && localSearchType === 'policy') {
      setShowMessage('No data found!')
      setShowSpinner(false)
    } else if (policyTermsList && policyTermsList.length > 0 && localSearchType === 'policy') {
      setShowSpinner(false)
    }
  }, [policyTermsList, showSpinner, localSearchType, fetchPolicyTerms, fetchPolicyClaims, localSearchValue])

  useEffect(() => {
    if (policyClaimsList && policyClaimsList.length === 0 && localSearchType === 'policy') {
      // setShowClaimsDataMessage('No claims data found!')
      setShowSpinner(false)
    } else if (policyClaimsList && policyClaimsList.length > 0 && localSearchType === 'policy') {
      setShowClaimsDataMessage('')
      setShowSpinner(false)
    }
  }, [policyClaimsList, showSpinner, localSearchType, fetchPolicyTerms, fetchPolicyClaims, localSearchValue])

  useEffect(() => {
    if (selectedTerm &&  localSearchType === 'policy') {
      getTermCommonInfo(searchValue, selectedTerm)
      getTermInsuredInfo(searchValue, selectedTerm)
      getTermVehiclesInfo(searchValue, selectedTerm)
      // getVehicleCoveragesInfo(searchValue, selectedTerm, 7)
      // getDriverIncidentsInfo(searchValue, selectedTerm,1)
      // getTermDriversInfo(searchValue, selectedTerm, 7)
      getTransactionHistoryForTerm(searchValue, selectedTerm)
      getVehicleInterests(searchValue, selectedTerm)
      getAgencyInfo(searchValue, selectedTerm)
      getPolicyNotesData(searchValue)
      getPolicyRecipientsData(searchValue, selectedTerm)

    }
  }, [selectedTerm, searchValue, searchType, localSearchType, getTermCommonInfo, getPolicyRecipientsData, getPolicyNotesData, getAgencyInfo, getVehicleInterests, getTermInsuredInfo, getTermVehiclesInfo, getVehicleCoveragesInfo, getTermDriversInfo, getDriverIncidentsInfo, getTransactionHistoryForTerm])

  const handleOnChange = (value) => {
    setLocalSearchType(value)
  }

  const handleInputChange = event => {
    setLocalSearchValue(event.target.value)
  }

  const updatePolicyClaims = claim => {
    const [selectedClaim] = policyClaimsList.filter(claimObj => claimObj.b69_claim_occur === claim)
    updateClaimsInfo({
      occuranceNo: selectedClaim.b69_claim_occur,
      policyNumber: selectedClaim.a00_pnum,
      lossDate: selectedClaim.b70_loss_date,
      type: selectedClaim.d74_claim_type
    })
    
    setLocalSearchType('claims')
    setLocalSearchValue(claim)
    updateSearch(claim)
    
    navigate(`/claims/${claim}`)
  }

  const downloadFiles = (e) => {
    e.preventDefault()
    setDownloadingFile(true)
    downloadFilesFromServer(localSearchValue)
  }

  return (
    <>
      <Row className="page-content bg-white">
        <Card bordered={false} className="input-card">
          <Row>
            <Title className="title" level={1}>
              SEARCH
            </Title>
          </Row>
          <Row>
            <Text className="subtext">Search by Claim#, Policy# or Last Name</Text>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Select
              defaultValue="claims"
              style={{
                width: 120
              }}
              onChange={handleOnChange}
              value={localSearchType}
            >
              <Option value="claims">Claims #</Option>
              {/* <Option value="ssn">SSN #</Option>
                <Option value="tin">TIN #</Option> */}
              <Option value="policy">
                Policy #
              </Option>
              <Option value="name">Last Name</Option>
            </Select>
            <Search onSearch={updateSearch} onChange={handleInputChange} value={localSearchValue} style={{ width: 160, marginLeft: 12 }} />
          </Row>
          <Row style={{ marginTop: 15 }}>
            {/* <>{showSpinner ? <Spin indicator={antIcon} /> : (claimsList && claimsList.length > 0 )? <Table columns={columns} dataSource={claimsList} pagination={false} /> : <Title level={2}>{showMessage}</Title>}</> */}
            <>{showSpinner
              ? <Spin indicator={antIcon} />
              : (claimsList && claimsList.length > 0 && localSearchType !== 'policy')
                ? <><Table columns={columns} dataSource={claimsList} pagination={false} />
                  <Row gutter={16} style={{marginTop: 30, width: '100%'}}>
                  <Collapse
                    accordion='true'
                    style={{width: "100%"}}
                    defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                  >
                  <Panel header="Images & Attachments" style={{width: "100%"}} extra={
                    <Button
                      onClick={(e) => {
                        downloadFiles(e)
                      }}
                      loading={downloadingFile}
                      disabled={downloadingFile}
                      style={{background: 'rgb(55, 105, 152)', color: 'white'}}
                    >
                      Download
                    </Button>
                  }>
                    <ImagesAttachmentsInfo imagesAttInfo={imagesAndAttachments} />
                  </Panel>
                  </Collapse></Row>
                  <Row gutter={16} style={{marginTop: 30, width: '100%'}}>
                  <Collapse
                    accordion='true'
                    style={{width: "100%"}}
                    defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                  >
                  <Panel header="Images & Attachments Audit" style={{width: "100%"}}>
                    <ImagesAndAttachmentsAudit imagesAttInfo={imagesAndAttachmentsAudit} />
                  </Panel>
                  </Collapse></Row>
                </>
                : (claimsList && claimsList.length === 0 && localSearchType !== 'policy')
                  ? <Title level={2}>{showMessage}</Title>
                  : (localSearchType === 'policy' && policyTermsList && policyTermsList.length > 0)
                    ? <PolicyStructure
                        updatePolicyTerm={updatePolicyTerm}
                        agencyInfo={agencyInfo}
                        policyNotesInfo={policyNotesInfo}
                        termCommonInfo={termCommonInfo}
                        termInsuredInfo={termInsuredInfo}
                        termVehicleInfo={termVehicleInfo}
                        selectedTerm={selectedTerm}
                        policyRecipentsInfo={policyRecipentsInfo}
                        policyTermsList={policyTermsList}
                        searchValue={searchValue}
                        vehicleCoverages={vehicleCoverages}
                        getVehicleInfo={getVehicleInfo}
                        termDriverInfo={termDriverInfo}
                        vehicleInfo={vehicleInfo}
                        vehicleInterests={vehicleInterests}
                        driverIncidentInfo={driverIncidentInfo}
                        termTransactionHistory={termTransactionHistory}
                        getVehicleCoveragesInfo={getVehicleCoveragesInfo}
                        getTermDriversInfo={getTermDriversInfo}
                        getDriverIncidentsInfo={getDriverIncidentsInfo}
                        policyClaimsList={policyClaimsList}
                        showClaimsDataMessage={showClaimsDataMessage}
                        updatePolicyClaims={updatePolicyClaims}
                        imagesAndAttachments={imagesAndAttachments}
                        />
                    : <Title level={2}>{showMessage}</Title>
            }</>
          </Row>
        </Card>
      </Row>
    </>
  )
}

const mapStateToProps = ({ home, search }) => {
  return {
    claimsList: home.claimsList,
    driverIncidentInfo: home.driverIncidentInfo,
    policyTermsList: home.policyTermsList,
    policyClaimsList: home.policyClaimsList,
    searchType: search.searchType,
    searchValue: search.searchValue,
    selectedTerm: home.selectedTerm,
    termCommonInfo: home.termCommonInfo,
    termDriverInfo: home.termDriverInfo,
    termInsuredInfo: home.termInsuredInfo,
    imagesAndAttachments: home.imagesAndAttachments,
    imagesAndAttachmentsAudit: home.imagesAndAttachmentsAudit,
    termTransactionHistory: home.termTransactionHistory,
    termVehicleInfo: home.termVehicleInfo,
    vehicleInterests: home.vehicleInterests,
    vehicleCoverages: home.vehicleCoverages,
    agencyInfo: home.agencyInfo,
    downloadFile: home.downloadFile?.data,
    policyNotesInfo: home.policyNotesInfo,
    policyRecipentsInfo: home.policyRecipentsInfo,
    vehicleInfo: home.vehicleInfo
  }
}

const mapDispatchToProps = {
  getAgencyInfo,
  fetchClaims,
  fetchPolicyTerms,
  fetchPolicyClaims,
  getTermCommonInfo,
  fetchImagesAttachmentByPolicyNumber,
  fetchImagesAttachmentAuditByPolicyNumber,
  getTermInsuredInfo,
  getTermVehiclesInfo,
  getVehicleInfo,
  updateClaimsInfo,
  updatePolicyTerm,
  updateSearchType,
  updateSearchValue,
  getVehicleCoveragesInfo,
  getPolicyRecipientsData,
  getPolicyNotesData,
  getVehicleInterests,
  getTermDriversInfo,
  downloadFilesFromServer,
  getDriverIncidentsInfo,
  getTransactionHistoryForTerm
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

