import React from 'react'
import { Card } from 'antd'
import { useParams } from 'react-router-dom'

import './Details.css'

const Details = () => {
  const { detailsId } = useParams()

  return <Card bordered={false}>{detailsId}</Card>
}

Details.propTypes = {}

Details.defaultProps = {}

export default Details
