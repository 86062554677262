import React from 'react'
import moment from 'moment'
import { Table } from 'antd'

const ReceiptsInfo = ({ receiptsInfo }) => {
  const transactionKeys = {
    'M+': 'Misapplied Receipt',
    'M-': 'Misapplied Offset',
    "PD": 'Paid Receipt',
    'N+': 'NSF Receipt',
    'N-': 'NSF Offset'
  }

  const columns = [
    {
      title: 'Policy #',
      dataIndex: 'policynum',
      key: 'policynum',
      width: 200
    },
    {
      title: 'Term',
      dataIndex: 'term',
      key: 'term',
      width: 200
    },
    {
      title: 'Transaction Date',
      render: (text, record) => <span>{record.transdate ? moment(record.transdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'transdate',
      width: 200
    },
    {
      title: 'Transaction Amount',
      width: 200,
      key: 'transactionamt',
      dataIndex: 'transactionamt'
    },
    {
      title: 'Transaction',
      render: (text, record) => <span>{transactionKeys[record.transaction]}</span>,
      key: 'transaction',
      width: 200
    },
    {
      title: 'Pay Plan',
      key: 'payplan',
      width: 200,
      dataIndex: 'payplan'
    },
    {
      title: 'Comments',
      key: 'comments',
      width: 400,
      dataIndex: 'comments'
    },
    {
      title: 'Control Date',
      render: (text, record) => <span>{record.controldate ? moment(record.controldate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'controldate',
      width: 200
    },
    {
      title: 'Control Number',
      key: 'controlnumber',
      width: 200,
      dataIndex: 'controlnumber'
    },
    {
      title: 'Check Number',
      key: 'checknumber',
      width: 200,
      dataIndex: 'checknumber'
    }
  ]

  return (
    <Table
      columns={columns}
      scroll={{
        x: 2500
      }}
      pagination={false}
      dataSource={receiptsInfo}
    />
  )
}

ReceiptsInfo.propTypes = {}

ReceiptsInfo.defaultProps = {}

export default ReceiptsInfo
