import React from 'react'
import { Navigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

const PrivateRoute = ({ children }) => {
  const isLogin = JSON.parse(localStorage.getItem('cgi-key'))

  return isLogin
    ? (
    <>
      <Header selectedOpton={0} />
      {children}
      <Footer />
    </>
      )
    : (
    <Navigate to="/login" />
      )
}

export default PrivateRoute
