import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Card, Table, Spin, Divider, Row, Col, Typography } from 'antd'
import { connect } from 'react-redux'
import { fetchClaimsClaimants, updateClaimsTab, updateClaimantsNumber } from '../../store/actions'
import { formatPhoneNumber } from '../../utils/StringUtils'

const { Title, Text } = Typography

const antIcon = (
  <LoadingOutlined
    spin
    style={{
      fontSize: 24
    }}
  />
)
const NotesInfo = ({ notesInfo }) => {
  
  const columns = [
    {
      title: 'Policy #',
      dataIndex: 'policynumber',
      key: 'policynumber',
      width: 200
    },
    {
      title: 'Edition',
        dataIndex: 'edition',
        key: 'edition',
        width: 200
      },
        {
        title: 'Date',
        render: (text, record) => <span>{record.notedate ? moment(record.notedate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
        key: 'notedate',
        width: 200
      },
      {
        key: 'Title',
        width: 200,
        title: 'Note Title',
        dataIndex: 'notetitle',
      },
        {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
        width: 200
      },
        {
        title: 'User Id',
        key: 'userid',
        width: 200,
        dataIndex: 'userid'
      }
  ]

  return <Table
  columns={columns} 
  scroll={{
    x: 1200,
  }}
  pagination={false}
  dataSource={notesInfo}
  />
}

NotesInfo.propTypes = {}

NotesInfo.defaultProps = {}

export default NotesInfo
