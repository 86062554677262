import React, { useEffect, useState } from 'react'
import { Col, Row, Select, Input, Typography } from 'antd'
import { updateSearchValue, updateSearchType } from '../../store/actions'
import { useNavigate } from 'react-router-dom';

import './Footer.css'
import { connect } from 'react-redux'

const { Option } = Select

const { Search } = Input

const { Text } = Typography

const Footer = ({ updateSearchValue, updateSearchType, searchType, searchValue }) => {
  const navigate = useNavigate();
  const [localSearchValue, setLocalSearchValue] = useState()
  const [localSearchType, setLocalSearchType] = useState()
  const isUploadLogin = JSON.parse(localStorage.getItem('cgi-key-upload'))

  useEffect(() => {
    if (searchType) {
      setLocalSearchType(searchType)
    }
  }, [searchType])

  useEffect(() => {
    if (searchValue) {
      setLocalSearchValue(searchValue)
    }
  }, [searchValue])

  const updateSearch = value => {
    updateSearchValue(value)
    updateSearchType(localSearchType)
    window.localStorage.setItem('searchValue', JSON.stringify(value))
    window.localStorage.setItem('searchType',JSON.stringify(localSearchType))
    navigate('/home')
  }

  const handleOnChange = (value) => {
    setLocalSearchType(value)
  }

  return (
    <footer>
      <Row className="footer" style={{ marginTop: 25 }}>
        {!isUploadLogin && <Col lg={22} md={22} sm={2} style={{ textAlign: 'center' }} xl={22} xs={2}>
          <Select
            defaultValue="claims"
            style={{
              width: 120
            }}
            onChange={handleOnChange}
            value={localSearchType}
          >
            <Option value="claims">Claims #</Option>
            {/* <Option value="ssn">SSN #</Option> */}
            {/* <Option value="tin">TIN #</Option> */}
            <Option value="policy">
              Policy #
            </Option>
            <Option value="name">Last Name</Option>
          </Select>
          <Search onSearch={updateSearch} value={localSearchValue} onChange={(event) => setLocalSearchValue(event.target.value)} style={{ width: 160, marginLeft: 12 }} />
        </Col>}
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col lg={23} md={5} sm={2} style={{ textAlign: 'center' }} xl={23} xs={2}>
          <Text className="footer-title" type="secondary">
            Copyright© 2022 CURE. All rights reserved
          </Text>
        </Col>
      </Row>
    </footer>
  )
}

Footer.propTypes = {}

const mapStateToProps = ({ search }) => ({
  searchValue: search.searchValue,
  searchType: search.searchType
})

const mapDispatchToProps = {
  updateSearchValue,
  updateSearchType
}


export default connect(mapStateToProps, mapDispatchToProps)(Footer)
