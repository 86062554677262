import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

const VehicleInterests = ({ vehicleInterests }) => {
  
  const nameTypes = {
    "LP" : "Loss Payee",
    "AI" : "Additional Insured"
  }

  const columns = [
    {
      title: 'Type',
      render: (text, record) => <span>{nameTypes[record.b25_nametype]}</span>,
      key: 'b25_nametype',
      width: 200
    },
    {
      title: 'Name',
        dataIndex: 'b27_name1',
        key: 'b27_name1',
        width: 200
      },
        {
        title: 'Address',
        dataIndex: 'b28_addr1',
        key: 'b28_addr1',
        width: 200
      },
      {
        title: 'City',
        key: 'b30_city',
        width: 200,
        dataIndex: "b30_city"
      },
      {
        title: 'State',
        key: 'b31_state',
        width: 200,
        dataIndex: "b31_state"
      },
        {
          title: 'Zip',
          key: 'b32_zip',
          width: 200,
          dataIndex: "b32_zip"
      },
        {
        title: 'Unit',
        dataIndex: 'b79_unit',
        key: 'b79_unit',
        width: 200
      },
      {
        key: 'a08_fdate',
        width: 200,
        title: 'Effective Date',
        render: (text, record) => <span>{record.a08_fdate ? moment(record.a08_fdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>
      },
        {
        title: 'Expiration Date',
        render: (text, record) => <span>{record.a09_xdate ? moment(record.a09_xdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
        key: 'a09_xdate',
        width: 200
      }
  ]

  return <Table
  columns={columns} 
  scroll={{
    x: 2500,
  }}
  pagination={false}
  dataSource={vehicleInterests}
  />
}

VehicleInterests.propTypes = {}

VehicleInterests.defaultProps = {}

export default VehicleInterests
