import React from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'

import './Claimants.css'

import { Row, Col, Divider, Typography } from 'antd'

const { Title, Text } = Typography

const ClaimantsDetail = () => {
  return (
    <>
      <Row>
        <a>
          <ArrowLeftOutlined />
        </a>
        <Title level={5}>JENNIFER LARK</Title>
      </Row>
      <Row>
        <Title level={5}>GENERAL INFORMATION</Title>
        <Divider dashed style={{ borderColor: '#000' }} />
      </Row>
      <Row>
        <Col span={12}>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Occurrence number:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">19E0001126</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Type:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">Reserve</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Reported date:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">3/21/2019</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Accident State: </Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">New Jersey</Text>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Submitted loss data:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text" />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Loss data:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">3/21/2019</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Loss time:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">5:30 PM</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Entered data:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">3/22/2019</Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Title level={5}>RISK COVERAGE: BODILY INJURY LIABILITY-BI</Title>
        <Divider dashed style={{ borderColor: '#000' }} />
      </Row>
      <Row>
        <Col span={12}>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Policy number:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">NC10213410</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Edition:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">1</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Effective date:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">2/15/2019</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Expiration date: </Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">8/15/2019</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Program:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">Standard Policy</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Insured: </Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">Jimmara Carpenter</Text>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Address:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">4207 North Dr</Text>
              <br />
              <Text className="subtext text">Atlantic City, NJ 08401</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Work phone:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text" />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Home phone:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">(609) 517-9257</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">Mobile phone:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">(609) 517-9257</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text className="subtext text">E-mail address:</Text>
            </Col>
            <Col span={16}>
              <Text className="subtext text">jlow119@gmail.com</Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Title level={5}>RISK COVERAGE: COLLISION</Title>
        <Divider dashed style={{ borderColor: '#000' }} />
      </Row>
      <Row>
        <Col span={18}>
          <Row>
            <Col span={10}>
              <Text className="subtext text">Loss reserve:</Text>
            </Col>
            <Col span={8}>
              <Text className="subtext text">$1,000.00</Text>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Text className="subtext text">Total loss paid to date:</Text>
            </Col>
            <Col span={8}>
              <Text className="subtext text">$21,085.44</Text>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Text className="subtext text">Defense and cost containment reserve:</Text>
            </Col>
            <Col span={8}>
              <Text className="subtext text">$817.75 </Text>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Text className="subtext text">Total defense and cost containment paid to date: </Text>
            </Col>
            <Col span={8}>
              <Text className="subtext text">$9714.49 </Text>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Text className="subtext text">Adjusting and other reserve: </Text>
            </Col>
            <Col span={8}>
              <Text className="subtext text">$0.00</Text>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Text className="subtext text">Total adjusting and other paid to date: </Text>
            </Col>
            <Col span={8}>
              <Text className="subtext text">$0.00</Text>
            </Col>
          </Row>
        </Col>
        <Col span={6} />
      </Row>
    </>
  )
}

ClaimantsDetail.propTypes = {}

ClaimantsDetail.defaultProps = {}

export default ClaimantsDetail
