import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Divider, Row, Typography, Spin, Table } from 'antd'
import moment from 'moment'

import './Summary.css'
import { connect } from 'react-redux'
import { fetchClaimsSummary, fetchCoverageInfo } from '../../store/actions'

const { Title, Text } = Typography
const { Column, ColumnGroup } = Table

const antIcon = (
  <LoadingOutlined
    spin
    style={{
      fontSize: 24
    }}
  />
)
const Summary = ({ fetchClaimsSummary, fetchCoverageInfo, id, claimsCoverageInfo, claimsSummary, claimsInfo }) => {
  const [showSpinner, setShowSpinner] = useState(true)
  const [claimsData, setClaimsData] = useState({})

  useEffect(() => {
    if (claimsData) {
      setShowSpinner(false)
    }
  }, [claimsData])

  useEffect(() => {
    if (id) {
      fetchClaimsSummary(id)
      setShowSpinner(true)
      fetchCoverageInfo(id)
    }
  }, [id, fetchClaimsSummary])

  useEffect(() => {
    if (claimsSummary && claimsSummary.length > 0) {
      const [claimsData] = claimsSummary
      setShowSpinner(false)
      setClaimsData(claimsData)
    }
  }, [claimsSummary])

  return (
    <>
      {showSpinner ? (
        <Spin indicator={antIcon} />
      ) : (
        <Card bordered={false}>
          <Row>
            <Title level={5}>OCCURRENCE INFORMATION</Title>
            <Divider dashed style={{ borderColor: '#000' }} />
          </Row>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Occurrence number:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsInfo.occuranceNo}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Type:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsInfo.type}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Reported date:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">
                    {claimsData.d43_reported_date ? moment(claimsData.d43_reported_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Accident State: </Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsData.d87_acc_state}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Loss date:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">
                    {claimsInfo.lossDate ? moment(claimsInfo.lossDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Entered date:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">
                    {claimsData.h53_ndate ? moment(claimsData.h53_ndate, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY h:mm:ss A') : ''}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Title level={5}>POLICY INFORMATION</Title>
            <Divider dashed style={{ borderColor: '#000' }} />
          </Row>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Policy number:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsInfo.policyNumber}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Edition:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsData.a06_edition}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Effective date:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">
                    {claimsData.polinfoeffectivedate ? moment(claimsData.polinfoeffectivedate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Expiration date: </Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">
                    {claimsData.polinfoexpirationdate ? moment(claimsData.polinfoexpirationdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Program:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsData.polinfoprogramcode}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Insured: </Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsData.b27_name1}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Address:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsData.b28_addr1}</Text>
                  <br />
                  <Text className="subtext text">
                    {claimsData.b30_city}, {claimsData.b31_state} {claimsData.b32_zip}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Work phone:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsData.polinfoworkphoneinsured}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Home phone:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsData.polinfohomephoneinsured}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">Mobile phone:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsData.polinfomobilephoneinsured}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text className="subtext text">E-mail address:</Text>
                </Col>
                <Col span={16}>
                  <Text className="subtext text">{claimsData.polinfoemailaddressinsured}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row style={{ marginTop: 15 }}>
          <Title level={5}>RESERVE AND PAYMENT INFORMATION</Title>
          <Divider dashed style={{ borderColor: "#000" }} />
        </Row>
        <Row>
          <Col span={18}>
            <Row>
              <Col span={10}>
                <Text className="subtext text">Loss reserve:</Text>
              </Col>
              <Col span={8}>
                <Text className="subtext text">${claimsData.rpiinfocurrentlossreserve}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Text className="subtext text">Total loss paid to date:</Text>
              </Col>
              <Col span={8}>
                <Text className="subtext text"></Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Text className="subtext text">
                  Defense and cost containment reserve:
                </Text>
              </Col>
              <Col span={8}>
                <Text className="subtext text"></Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Text className="subtext text">
                  Total defense and cost containment paid to date:{" "}
                </Text>
              </Col>
              <Col span={8}>
                <Text className="subtext text"></Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Text className="subtext text">
                  Adjusting and other reserve:{" "}
                </Text>
              </Col>
              <Col span={8}>
                <Text className="subtext text"></Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Text className="subtext text">
                  Total adjusting and other paid to date:{" "}
                </Text>
              </Col>
              <Col span={8}>
                <Text className="subtext text"></Text>
              </Col>
            </Row>
          </Col>
          <Col span={6}></Col>
        </Row> */}
        <Row style={{ marginTop: 15 }}>
            <Title level={5}>COVERAGE INFORMATION</Title>
            <Divider dashed style={{ borderColor: '#000' }} />
          </Row>
          <Row>
            <Col span={24}>
            <Table dataSource={claimsCoverageInfo} style={{width: '100%'}}>
                <Column width={300} title="Coverage" dataIndex="c87_description" key="c87_description" />
                <ColumnGroup width={300} title="Limit">
                  <Column width={150} className='coverage-group' title="" dataIndex="c07_limit_1" key="c07_limit_1"
                          render= {(text, record) => <span>{record.c07_limit_1 ? `$${record.c07_limit_1}` : '' }</span>}

                  />
                  <Column width={150} className='coverage-group' title="" dataIndex="c07_limit_2" key="c07_limit_2" 
                          render= {(text, record) => <span>{record.c07_limit_2 ? `$${record.c07_limit_2}` : '' }</span>} />
                </ColumnGroup>
                <Column width={200} title="Deductible" dataIndex="b85_ded_amt" key="b85_ded_amt" 
                          render= {(text, record) => <span>{record.b85_ded_amt ? `$${record.b85_ded_amt}` : '' }</span>} />
                <Column width={200} title="Vehicle" dataIndex="c86_unitdesc" key="c86_unitdesc" />
                <Column width={200} title="VIN" dataIndex="c59_id" key="c59_id" />                
              </Table>
            </Col>
          </Row>
        </Card>
      )}
    </>
  )
}

Summary.propTypes = {}

Summary.defaultProps = {}

const mapStateToProps = ({ home, claims }) => ({
  claimsSummary: claims.claimsSummary,
  claimsInfo: claims.claimsInfo,
  claimsCoverageInfo: claims.claimsCoverageInfo
})

const mapDispatchToProps = {
  fetchClaimsSummary,
  fetchCoverageInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary)
