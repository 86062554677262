import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin, Space, Collapse, Card, Row, Descriptions } from 'antd'
import './Transactions.css'
import { connect } from 'react-redux'
import { fetchClaimsTransactions } from '../../store/actions'

const { Panel } = Collapse;
const antIcon = (
  <LoadingOutlined
    spin
    style={{
      fontSize: 24
    }}
  />
)

// const columns = [
//   {
//     title: 'ENTRY DATE',
//     dataIndex: 'h53_ndate',
//     key: 'h53_ndate',
//     width: 220
//   },
//   {
//     title: 'RESERVE TYPE',
//     dataIndex: 'd32_transact',
//     key: 'd32_transact',
//     width: 150
//   },
//   {
//     title: 'RESERVE/RECOVERABLE',
//     dataIndex: 'd32_transact_act',
//     key: 'd32_transact_act',
//     width: 150
//   },
//   {
//     title: 'PAYMENT/ RECOVERED',
//     dataIndex: 'd64_amount',
//     key: 'd64_amount',
//     width: 150
//   },
//   {
//     title: 'PAY TO/RECEIVED FROM',
//     dataIndex: 'b27_pay_to',
//     key: 'b27_pay_to',
//     width: 300
//   }
// ]

// const columns = [
//   {
//     title: 'ENTRY DATE',
//     dataIndex: 'h53_ndate',
//     key: 'h53_ndate',
//     width: 220
//   },
//   {
//     title: 'RESERVE TYPE',
//     dataIndex: 'd32_transact',
//     key: 'd32_transact',
//     width: 150
//   },
//   {
//     title: 'RESERVE/RECOVERABLE',
//     dataIndex: 'd32_transact_act',
//     key: 'd32_transact_act',
//     width: 150
//   },
//   {
//     title: 'PAYMENT/ RECOVERED',
//     dataIndex: 'd64_amount',
//     key: 'd64_amount',
//     width: 150
//   },
//   {
//     title: 'PAY TO/RECEIVED FROM',
//     dataIndex: 'b27_pay_to',
//     key: 'b27_pay_to',
//     width: 300
//   }
// ]

const Transactions = ({ id, claimantNumber, fetchClaimsTransactions, claimsTransactions }) => {
  const [showSpinner, setShowSpinner] = useState(true)
  const [reserveTypes, setReserveTypes] = useState([])

  useEffect(() => {
    if (id && claimantNumber) {
      fetchClaimsTransactions(id, claimantNumber)
    }
  }, [id, fetchClaimsTransactions, claimantNumber])

  useEffect(() => {
    if (claimsTransactions) {
      setShowSpinner(false)
      const types = Object.keys(claimsTransactions)
      setReserveTypes(types)
    }
  }, [claimsTransactions])


  return <>{showSpinner ? <Spin indicator={antIcon} /> : <Space direction="vertical" style={{width: '100%'}}>
    {
      reserveTypes && reserveTypes.map((type, index) => {
        return claimsTransactions[type] && claimsTransactions[type].length > 0 ?
          <Collapse collapsible="header" defaultActiveKey={[index]} className="transactions-list">
            <Panel header={<h1>{type}</h1>} key="1">
              {/* <Table columns={columns} dataSource={claimsTransactions[type]} pagination={false} /> */}
              {
                claimsTransactions[type].map(item => <Row gutter={16} className="transactions">
                <Card bordered={false}>
                  <Descriptions
                      bordered
                      size='small'
                    >
                      <Descriptions.Item label="Entry Date">{item.h53_ndate}</Descriptions.Item>
                      <Descriptions.Item label="Transaction Description">{item.d32_transact}</Descriptions.Item>
                      <Descriptions.Item label="Reserve/Recoverable">{item.d32_transact_act}</Descriptions.Item>
                      <Descriptions.Item label="Payment/Recovered">{item.d64_amount}</Descriptions.Item>
                      <Descriptions.Item label="Pay To/Received From">{item.b27_pay_to}</Descriptions.Item>
                      <Descriptions.Item label="Name 2"></Descriptions.Item>
                      <Descriptions.Item label="Address 1">{item.b28_addr1}</Descriptions.Item>
                      <Descriptions.Item label="Address 2">{item.b28_addr2}</Descriptions.Item>
                      <Descriptions.Item label="City">{item.b30_city}</Descriptions.Item>
                      <Descriptions.Item label="State/Province">{item.b31_state}</Descriptions.Item>
                      <Descriptions.Item label="Zip/Postal Code">{item.b32_zip}</Descriptions.Item>
                      <Descriptions.Item label="Government ID Type">{item.h60_fn_ssn_flg}</Descriptions.Item>
                      <Descriptions.Item label="Government ID Number">{item.h60_fein_ssn}</Descriptions.Item>
                      <Descriptions.Item label="Check Date">{item.d61_rec_check_dt}</Descriptions.Item>
                      <Descriptions.Item label="Check Number">{item.d62_rec_check_num}</Descriptions.Item>
                      <Descriptions.Item label="A/P Check Number"></Descriptions.Item>
                      <Descriptions.Item label="A/P Voided Date"></Descriptions.Item>
                      <Descriptions.Item label="Comments">{item.f16_reason}</Descriptions.Item>
                      {/* <Descriptions.Item label="User Name"></Descriptions.Item>
                      <Descriptions.Item label="Withholding"></Descriptions.Item>
                      <Descriptions.Item label="1099/T4A Pay Type"></Descriptions.Item>
                      <Descriptions.Item label="Issued to Name Type"></Descriptions.Item> */}
                      <Descriptions.Item label="A/P Check Date"></Descriptions.Item>
                      <Descriptions.Item label="A/P Paid Date"></Descriptions.Item>
                      {/* <Descriptions.Item label="Claim Type"></Descriptions.Item> */}
                      <Descriptions.Item label="Status"></Descriptions.Item>
                      {/* <Descriptions.Item label="Payment Number"></Descriptions.Item>
                      <Descriptions.Item label="Pay Description/Code"></Descriptions.Item>
                      <Descriptions.Item label="Collect Fee"></Descriptions.Item>
                      <Descriptions.Item label="1099/T4A"></Descriptions.Item> */}
                      <Descriptions.Item label="Approval Status">{item.approval_status}</Descriptions.Item>
                      <Descriptions.Item label="Revised By">{item.revised_by}</Descriptions.Item>
                      <Descriptions.Item label="Revised Date">{item.revised_date}</Descriptions.Item>
                                          
                    </Descriptions>
                  </Card>
              </Row>)
              }
            </Panel>
          </Collapse>
          : null
      })
    }
  </Space>}</>
}

Transactions.propTypes = {}

Transactions.defaultProps = {}

const mapStateToProps = ({ home, claims }) => ({
  claimsSummary: claims.claimsSummary,
  tabIndex: claims.tabIndex,
  claimsTransactions: claims.claimsTransactions,
  claimantNumber: claims.selectedClaimant
})

const mapDispatchToProps = {
  fetchClaimsTransactions
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
