import { FETCH_CLAIMS_SUMMARY, UPLOAD_FILE_TO_STORE, UPDATE_SELECTED_CLAIMANT, FETCH_CLAIMS_COVERAGE_INFO, UPDATE_TAB_INDEX, FETCH_CLAIMS_NOTES, FETCH_CLAIMS_TRANSACTIONS, FETCH_CLAIMS_CLAIMANTS, UPDATE_CLAIMS_TABS, RESET_TABS, UPDATE_CLAIMS_INFO } from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CLAIMS_SUMMARY:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_TAB_INDEX:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_CLAIMS_NOTES:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_CLAIMS_TRANSACTIONS:
      return {
        ...state,
        ...action.payload
      }
    case UPLOAD_FILE_TO_STORE:
      return {
        ...state,
        uploadedFiles: action.payload
      }
    case FETCH_CLAIMS_CLAIMANTS:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_CLAIMS_COVERAGE_INFO:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_CLAIMS_TABS:
      return {
        ...state,
        ...action.payload
      }
    case RESET_TABS:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_CLAIMS_INFO:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_SELECTED_CLAIMANT:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
