export const AGENCY_INFO = 'AGENCY_INFO'
export const DISPLAY_DRIVER_INCIDENTS = 'DISPLAY_DRIVER_INCIDENTS'
export const DISPLAY_IMAGES_ATTACHMENTS = 'DISPLAY_IMAGES_ATTACHMENTS'
export const DISPLAY_IMAGES_ATTACHMENTS_AUDIT = 'DISPLAY_IMAGES_ATTACHMENTS_AUDIT'
export const DISPLAY_NOTES_INFO = 'DISPLAY_NOTES_INFO'
export const DISPLAY_RECIPIENTS_INFO = 'DISPLAY_RECIPIENTS_INFO'
export const DISPLAY_TERM_COMMON_INFO = 'DISPLAY_TERM_COMMON_INFO'
export const DISPLAY_TERM_INSURED_INFO = 'DISPLAY_TERM_INSURED_INFO'
export const DISPLAY_TRANSACTION_HISTORY_FOR_TERM = 'DISPLAY_TRANSACTION_HISTORY_FOR_TERM'
export const DISPLAY_VEHICLE_COVERAGES = 'DISPLAY_VEHICLE_COVERAGES'
export const DISPLAY_VEHICLE_INFO = 'DISPLAY_VEHICLE_INFO'
export const FETCH_ATTACHMENT_SIGNED_URL = 'FETCH_ATTACHMENT_SIGNED_URL'
export const DOWNLOAD_HISTORICAL_FILES = 'DOWNLOAD_HISTORICAL_FILES'
export const FETCH_CLAIMS = 'FETCH_CLAIMS'
export const FETCH_CLAIMS_CLAIMANTS = 'FETCH_CLAIMS_CLAIMANTS'
export const FETCH_CLAIMS_COVERAGE_INFO = 'FETCH_CLAIMS_COVERAGE_INFO'
export const FETCH_CLAIMS_NOTES = 'FETCH_CLAIMS_NOTES'
export const FETCH_CLAIMS_SUMMARY = 'FETCH_CLAIMS_SUMMARY'
export const FETCH_CLAIMS_TRANSACTIONS = 'FETCH_CLAIMS_TRANSACTIONS'
export const RESET_TABS = 'RESET_TABS'
export const SELECT_POLICY_CLAIMS = 'SELECT_POLICY_CLAIMS'
export const SELECT_POLICY_TERM = 'SELECT_POLICY_TERM'
export const SELECT_TERM_DRIVERS = 'SELECT_TERM_DRIVERS'
export const SELECT_TERM_VEHICLES = 'SELECT_TERM_VEHICLES'
export const UPDATE_CLAIMS_INFO = 'UPDATE_CLAIMS_INFO'
export const UPDATE_CLAIMS_TABS = 'UPDATE_CLAIMS_TABS'
export const UPDATE_POLICY_TERM_INFO = 'UPDATE_POLICY_TERM_INFO'
export const UPDATE_SEARCH_TYPE = 'UPDATE_SEARCH_TYPE'
export const UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE'
export const UPDATE_SELECTED_CLAIMANT = 'UPDATE_SELECTED_CLAIMANT'
export const UPDATE_TAB_INDEX = 'UPDATE_TAB_INDEX'
export const UPLOAD_FILE_ASSETS = 'UPLOAD_FILE_ASSETS'
export const UPLOAD_FILE_TO_STORE = 'UPLOAD_FILE_TO_STORE'
export const VEHICLE_INTERESTS = 'VEHICLE_INTERESTS'
export const FETCH_ATTACHMENT_SIGNED_URL_AUDIT = 'FETCH_ATTACHMENT_SIGNED_URL_AUDIT'
