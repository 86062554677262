import React, { useEffect, useState } from 'react'
import { Col, Input, Layout, Row, Select } from 'antd'
import PropTypes from 'prop-types'
import { updateSearchValue, updateSearchType } from '../../store/actions'
import { useNavigate } from 'react-router-dom';

import './Header.css'
import { connect } from 'react-redux'

const { Option } = Select

const { Search } = Input

const Header = ({ updateSearchValue, updateSearchType, searchType, searchValue }) => {
  const [localSearchValue, setLocalSearchValue] = useState()
  const [localSearchType, setLocalSearchType] = useState()
  const isUploadLogin = JSON.parse(localStorage.getItem('cgi-key-upload'))

  const navigate = useNavigate();

  const handleClick = () => {
    window.location = '/home'
  }

  useEffect(() => {
    if (searchType) {
      setLocalSearchType(searchType)
    }
  }, [searchType])

  useEffect(() => {
    if (searchValue) {
      setLocalSearchValue(searchValue)
    }
  }, [searchValue])


  const updateSearch = value => {
    updateSearchValue(value)
    updateSearchType(localSearchType)
    window.localStorage.setItem('searchValue', JSON.stringify(value))
    window.localStorage.setItem('searchType',JSON.stringify(localSearchType))
    navigate('/home')
  }

  const handleOnChange = (value) => {
    setLocalSearchType(value)
  }

  return (
    <>
      <Layout.Header className="menu-nav">
        <Row>
          <Col lg={4} md={5} sm={2} xl={3} xs={2}>
            <div className="logo-holder">
              <a onClick={handleClick}>
                <img alt="menu-nav-logo" src="https://assets.cure.com/img/main-site/cure-logo.png" />
              </a>
            </div>
          </Col>
          <Col className="" lg={12} md={5} sm={2} xl={13} xs={2} />
          {!isUploadLogin && <Col lg={8} md={5} sm={2} xl={8} xs={2}>
            <Select
              defaultValue="claims"
              style={{
                width: 120
              }}
              onChange={handleOnChange}
              value={localSearchType}
            >
              <Option value="claims">Claims #</Option>
              {/* <Option value="ssn">SSN #</Option>
              <Option value="tin">TIN #</Option> */}
              <Option value="policy">
                Policy #
              </Option>
              <Option value="name">Last Name</Option>
            </Select>
            <Search onSearch={updateSearch} style={{ width: 160, marginLeft: 12, marginTop: 15 }} />
          </Col>}
        </Row>
      </Layout.Header>
      <Layout.Header className="menu-nav-mobile">
      {!isUploadLogin && <Row>
          <Col lg={4} md={5} sm={5} xl={3} xs={10}>
            <div className="logo-holder">
              <a href="/">
                <img alt="nav-mobile-logo" src="https://assets.cure.com/img/main-site/cure-logo.png" />
              </a>
            </div>
          </Col>
          <Col lg={10} md={8} sm={3} xl={10} xs={14} />
          <Col lg={10} md={8} sm={16} style={{ minWidth: 300 }} xl={10} xs={14}>
            <Select
              defaultValue="claims"
              style={{
                width: 120
              }}
            >
              <Option value="claims">Claims #</Option>
              {/* <Option value="ssn">SSN #</Option>
                <Option value="tin">TIN #</Option> */}
              <Option value="policy">
                Policy #
              </Option>
              <Option value="name">Last Name</Option>
            </Select>
            <Search onSearch={updateSearch} value={localSearchValue} onChange={(event) => setLocalSearchValue(event.target.value)} style={{ width: 160, marginLeft: 12, marginTop: 15 }} />
          </Col>
        </Row>}
      </Layout.Header>
    </>
  )
}

Header.propTypes = {
  handleFeedbackClick: PropTypes.func,
  hideGlobalHeader: PropTypes.bool,
  maintainenceMessage: PropTypes.string,
  isMaintainenceLoading: PropTypes.bool
}

Header.defaultProps = {
  hideGlobalHeader: true,
  maintainenceMessage: '',
  isMaintainenceLoading: true
}

const mapStateToProps = ({ search }) => ({
  searchValue: search.searchValue,
  searchType: search.searchType
})

const mapDispatchToProps = {
  updateSearchValue,
  updateSearchType
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
