import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

const CoverageInfo = ({ vehicleCoverages }) => {
  
  const COVERAGE_DETAILS = {
    "110": "Bodily Injury",
    "120": "Property Damage",
    "130": "Medical Payments",
    "141": "Uninsured/Underinsured Motorist - Bodily Injury",
    "142": "Uninsured/Underinsured Motorist - Property Damage",
    "151": "Underinsured Motorist - Bodily Injury",
    "160": "Personal Injury Protection",
    "161": "Medical Benefits",
    "162": "Work Loss",
    "163": "Funeral Benefits",
    "164": "Accidental Death Benefits",
    "165": "Combination First Party Benefits",
    "166": "Extraordinary Medical",
    "167": "Added PIP",
    "168": "PIP Medical Exp Only",
    "200": "Collision",
    "210": "Comprehensive",
    "222": "Roadside Assistance",
    "230": "Rental Reimbursement",
    "959": "Policy Form",
    "990": "Policy Form",
    "993": "Surplus Contribution",
    "994": "Organizational Fee",
    "995": "NJ PLIGA",
    "997": "Reinstatement Fee",
    "998": "Patient Compensation Fund"
  }

  const columns = [
    {
      title: 'FDate',
      render: (text, record) => <span>{record.a29_fdate ? moment(record.a29_fdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'a29_fdate',
      width: 200
    },
    {
      title: 'New Date',
      render: (text, record) => <span>{record.a29_fdate ? moment(record.a30_xdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
        key: 'a30_xdate',
        width: 200
      },
        {
        title: 'End Date',
        render: (text, record) => <span>{record.a29_fdate ? moment(record.h53_ndate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
        key: 'h53_ndate',
        width: 200
      },
        {
        title: 'Coverage',
        render: (text, record) => <span>{record.c87_coverage ? `${COVERAGE_DETAILS[record.c87_coverage]} (${record.c87_coverage})`: ''}</span>,
        key: 'c87_coverage',
        width: 400
      },
        {
        title: 'Written',
        dataIndex: 'd42_written',
        key: 'd42_written',
        width: 200
      },
        {
        title: 'Annual',
        dataIndex: 'd41_annual',
        key: 'd41_annual',
        width: 200
      },
        {
        title: 'Total',
        dataIndex: 'd42_total',
        key: 'd42_total',
        width: 200
      },
        {
        title: 'Limit 1',
        dataIndex: 'c07_limit_1',
        key: 'c07_limit_1',
        width: 200
      },
        {
        title: 'Limit 2',
        dataIndex: 'c07_limit_2',
        key: 'c07_limit_2',
        width: 200
      },
        {
        title: 'Ded',
        dataIndex: 'b85_ded1',
        key: 'b85_ded1',
        width: 200
      },
      {
      title: 'End No.',
      dataIndex: 'a81_end_no',
      key: 'a81_end_no',
      width: 200
    },
      {
      title: 'End Descr.',
      dataIndex: 'g57_end_descr',
      key: 'g57_end_descr',
      width: 200
    }
  ]

  return <Table
  columns={columns} 
  scroll={{
    x: 2800,
  }}
  pagination={false}
  dataSource={vehicleCoverages}
  />
}

CoverageInfo.propTypes = {}

CoverageInfo.defaultProps = {}

export default CoverageInfo
