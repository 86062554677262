import React, { useEffect, useState } from 'react'
import { Card, Select, Space, Collapse, Typography, Row, Col } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons';

import './PolicyStructure.css'
import CommonInfo from '../CommonInfo/CommonInfo';
import InsuredInfo from '../InsuredInfo/InsuredInfo';
import VehicleInfo from '../VehiclesInfo/VehiclesInfo';
import CoverageInfo from '../CoverageInfo/CoverageInfo';
import DriversInfo from '../DriversInfo/DriversInfo';
import IncidentsInfo from '../IncidentsInfo/IncidentsInfo';
import TransactionsInfo from '../TransactionsInfo/TransactionsInfo';
import NotesInfo from '../NotesInfo/Notes';
import ReceiptsInfo from '../ReceiptsInfo/ReceiptsInfo'
import VehicleInterests from '../VehicleInterests/VehicleInterests';
import AgencyInfo from '../AgencyInfo/AgencyInfo';
import ImagesAttachmentsInfo from '../ImagesAttachmentsInfo/ImagesAttachmentsInfo'

import moment from 'moment';

const { Panel } = Collapse;
const { Title } = Typography

const PolicyStructure = ({
  agencyInfo,
  searchValue,
  policyTermsList,
  policyClaimsList,
  showClaimsDataMessage,
  imagesAndAttachments,
  updatePolicyTerm,
  updatePolicyClaims,
  selectedTerm, 
  termCommonInfo,
  termVehicleInfo,
  termInsuredInfo,
  vehicleInterests,
  policyNotesInfo,
  vehicleCoverages,
  termDriverInfo,
  vehicleInfo,
  policyRecipentsInfo,
  driverIncidentInfo,
  getTermDriversInfo,
  getVehicleInfo,
  getVehicleCoveragesInfo,
  termTransactionHistory,
  getDriverIncidentsInfo
}) => {

  const panelStyle = {
    marginBottom: 24,
    border: 'none',
  };

  const policyTermsListOptions = policyTermsList.map(({ a06_edition, eff_date, exp_date }) => ({
    label: `${a06_edition} - ${eff_date} - ${exp_date}`,
    value: `${a06_edition}`
  })).sort((a, b) => {
    return a.value - b.value;
  })

  const [policyClaimsListOptions, setPolicyClaimsListOptions] = useState([])
  
  useEffect(() => {
    if (policyClaimsList && policyClaimsList.length > 0) {

      const response = policyClaimsList.map(({ b69_claim_occur, b70_loss_date }) => ({
        label: `${b69_claim_occur} - ${moment(b70_loss_date).format('YYYY-MM-DD')}`,
        value: `${b69_claim_occur}`
      })).sort((a, b) => {
        return a.value - b.value;
      })

      setPolicyClaimsListOptions(response)
    
    }
  }, [policyClaimsList])

  return <Card bordered={false} className='policy-structure'>
      <Row gutter={16}>
        <Col>
        <p>Select Policy Term: </p>
      <Select
        style={{
          width: 250,
          float: 'left'
        }}
        onChange={updatePolicyTerm}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? '').includes(input)}
        options={policyTermsListOptions}
      />
        </Col>
        {policyClaimsListOptions && policyClaimsListOptions.length > 0 && <Col>
        <p>Select Claim: </p>
      <Select
        style={{
          width: 250,
          float: 'left'
        }}
        optionFilterProp="children"
        onChange={updatePolicyClaims}
        filterOption={(input, option) => (option?.label ?? '').includes(input)}
        options={policyClaimsListOptions}
      />
        </Col>}
      </Row>
      
      
      {
        showClaimsDataMessage && showClaimsDataMessage.length > 0 && <Title level={2}>{showClaimsDataMessage}</Title>
      }
      <br /><br /><br />
      { selectedTerm && <Space direction="vertical">
        <Collapse
        accordion='true'
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        <Panel header="Images & Attachments" key="1">
          <ImagesAttachmentsInfo imagesAttInfo={imagesAndAttachments} />
        </Panel>
        <Panel header="Notes" key="2">
          <NotesInfo notesInfo={policyNotesInfo} />
        </Panel>
        <Panel header="Receipts" key="3">
          <ReceiptsInfo receiptsInfo={policyRecipentsInfo} />
        </Panel>
        <Panel header="Common Info" key="4">
          <CommonInfo termCommonInfo={termCommonInfo} />
        </Panel>
        <Panel header="Insured Info" key="5">
          <InsuredInfo termInsuredInfo={termInsuredInfo} />
        </Panel>
        <Panel header="Agency Info" key="6">
          <AgencyInfo agencyInfo={agencyInfo} />
        </Panel>
        <Panel header="Vehicles Info" key="7">
          <VehicleInfo termVehicleInfo={termVehicleInfo} getVehicleInfo={getVehicleInfo} vehicleInfo={vehicleInfo} selectedTerm={selectedTerm} getTermDriversInfo={getTermDriversInfo} getVehicleCoveragesInfo={getVehicleCoveragesInfo} searchValue={searchValue} />
        </Panel>
        <Panel header="Coverages" key="8">
          <CoverageInfo vehicleCoverages={vehicleCoverages} />
        </Panel>
        <Panel header="Vehicle Interests " key="9">
          <VehicleInterests vehicleInterests={vehicleInterests} />
        </Panel>
        <Panel header="Drivers" key="10">
          <DriversInfo searchValue={searchValue} termDriverInfo={termDriverInfo} getDriverIncidentsInfo={getDriverIncidentsInfo} selectedTerm={selectedTerm} />
        </Panel>
        <Panel header="Incidents" key="11">
          <IncidentsInfo driverIncidentInfo={driverIncidentInfo} />
        </Panel>
        <Panel header="Transaction History" key="12">
          <TransactionsInfo termTransactionHistory={termTransactionHistory} />
        </Panel>
      </Collapse>
      </Space>}
    </Card>
}

PolicyStructure.propTypes = {}

PolicyStructure.defaultProps = {}

export default PolicyStructure
