import React, { useEffect, useState } from 'react'
import { InboxOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Row, message, Upload, Input, Typography, Card, Radio, Button, Spin } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { uploadFileToStore,
  updateSearchType,
  updateSearchValue, 
  uploadAssets} from '../../store/actions'

import './BulkUpload.css'
import { uploadFileAssets } from '../../services/Api'

const { Dragger } = Upload
const { Title, Text } = Typography
const { Search } = Input

const FILE_UPLOAD_SIZE = 5

const BulkUpload = ({ uploadedFile = {}, uploadFileToStore, searchValue, updateSearchValue, updateSearchType, searchType, uploadedFiles, uploadAssets }) => {
  const [fileList, setFileList] = useState([])
  const [localSearchValue, setLocalSearchValue] = useState(searchValue)
  const [localSearchType, setLocalSearchType] = useState(searchType)
  const [showSpinner, setShowSpinner] = useState(false)
  const antIcon = <LoadingOutlined spin style={{ fontSize: 48 }} />

  console.log(uploadedFile)
  useEffect(() => {
    if (uploadedFile && uploadedFile.file) {
      setFileList(uploadedFile.file)
    }
  }, [uploadedFile])

  useEffect(() => {
    if (uploadedFiles) {
      console.log(uploadedFiles)
      setShowSpinner(false)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }, [uploadedFiles])

  const props = {
    name: 'file',
    accept: '*',
    beforeUpload: (file) => {
      const isLt2M = file.size / 1024 / 1024 < FILE_UPLOAD_SIZE
      if (!isLt2M) {
        return message.error(
          `Document must smaller than ${FILE_UPLOAD_SIZE}MB!`
        )
      }
      console.log(file)
      // setFileList([file])
      // uploadFileToStore([file])
      return false
    },
    onChange: info => {
      console.log('Dropped files', info.fileList);
      setFileList(info.fileList)
      uploadFileToStore(info.fileList)
    },
    fileList,
    multiple: true,
    onRemove: () => {
      setFileList([])
      uploadFileToStore([])
    }
  }

  const updateSearch = value => {
    updateSearchValue(value)
    updateSearchType(localSearchType)
  }

  const handleInputChange = event => {
    setLocalSearchValue(event.target.value)
  }

  const handleOnChange = (e) => {
    setLocalSearchType(e.target.value)
  }

  const uploadFilesToServer = async () => {
    console.log(fileList, localSearchType, localSearchValue)
    uploadAssets(fileList, localSearchType, localSearchValue)
    // // fileList.map(async file => {
    //   const response = await uploadFileAssets(fileList, localSearchType, localSearchValue)
    //   console.log(response)
    // // })
    setShowSpinner(true)
  }
  const onFileChange = (event) => {
    const list = [...event.target.files]
    setFileList(list)
    event.target.value = null;
}

  const elements = []
  for (const key of Object.keys(fileList)) {
    console.log(key, fileList[key])
    elements.push(fileList[key].name)
  }
  // const fileListNames = fileList && fileList.length > 0 ? fileList.map(file => <Text>{file.name}</Text>) : ''
  
  const removeFile = (index) => {
    const list = [...fileList]
    const removedItem = list.splice(index, 1)
    setFileList(list)
  }

  return (
    <Row className='page-content bulk-upload'>
      <Card bordered={false} className="input-card">
        <Row>
          <Title className="title" level={1}>
            UPLOAD ASSETS
          </Title>
        </Row>
        <Row>
          <Text className="subtext">Upload by Claim# or Policy#</Text>
        </Row>
        <Row style={{ marginTop: 15, paddingLeft: 12 }}>
          <Radio.Group onChange={handleOnChange} value={localSearchType}>
            <Radio value={'C'}>Claims #</Radio>
            <Radio value={'P'}> Policy #</Radio>
          </Radio.Group>
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Input onSearch={updateSearch} onChange={handleInputChange} value={localSearchValue} style={{ width: 160, marginLeft: 12 }} />
        </Row><br/>
      {/* <Dragger {...props}>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>
          Drag and drop your file here
          <br />
          or browse your computer
        </p>
      </Dragger> */}
      <input className='mx-2' type='file' name='file' onChange={onFileChange} multiple></input><br /><br />
      {
        elements.map((element, index) => <><Text>{element}</Text><Button onClick={() => removeFile(index)} type="link" danger>
        Delete
      </Button><br /><br /></>)
      }
      <br />
      <Button icon={<UploadOutlined />} {
        ...(showSpinner ? { loading: true } : '')
      } onClick={() => uploadFilesToServer()}>Click to Upload</Button><br/>
      {uploadedFiles && (
        <Text level={6}>File(s) Uploaded!</Text>
      )}
      </Card>
    </Row>
  )
}

BulkUpload.propTypes = {
  uploadFileToStore: PropTypes.func,
  uploadedFile: PropTypes.object
}

const mapStateToProps = ({ search, claims, uploadFiles }) => {
  return {
    uploadedFile: claims.uploadedFile,
    searchType: search.searchType,
    searchValue: search.searchValue,
    uploadedFiles: uploadFiles.uploadFile
  }
}

const mapDispatchToProps = {
  uploadAssets,
  uploadFileToStore,
  updateSearchType,
  updateSearchValue
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkUpload)

