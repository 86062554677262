import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

const TransactionsInfo = ({ termTransactionHistory }) => {
  const TRANS_CODE = {
    BP: 'Backdated Change - Policy Level',
    UN: 'UW Review',
    RN: 'Renewal',
    CA: 'Cash',
    RE: 'Reinstate',
    DU: 'DB Utility',
    PR: 'Pre-Renewal Chg',
    CF: 'Cancel Flat',
    BU: 'Backdated Change - Other than Policy Level',
    EP: 'Earn Prem Bill',
    WC: 'Waive Charge',
    NP: 'Cancel Non-Pay',
    'N-': 'NSF',
    CS: 'Cancel Short',
    RQ: 'Questionnaire',
    RX: 'Renew Expire',
    CP: 'Cancel Prorata',
    RF: 'Refund',
    VR: 'Voided Refund',
    PT: 'Prior Term Adj',
    RL: 'Renew Lapse',
    NR: 'Do Not Renew',
    BA: 'Bal Due Adj',
    CN: 'Cancel Notice',
    RO: 'Renewal Offer',
    'P-': 'Prior Csh Rvrsl',
    'N ': 'Claim-Only Policy Submission',
    'M-': 'Misapplied Cash',
    IB: 'Install Bill',
    CT: 'Credit Transfer',
    70: 'Report Order',
    E: 'Change',
    EC: 'External Cancel',
    PP: 'Immediate Bill',
    PA: 'Immediate Bill',
    RW: 'Rewrite'
  }

  const columns = [
    {
      title: 'Date',
      render: (text, record) =>
        moment(record.n45_ndate).isValid() ? (
          <span>{record.n45_ndate ? moment(record.n45_ndate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: record.n45_ndate }} />
        ),
      key: 'n45_ndate',
      width: 200
    },
    {
      title: 'Chg FDate',
      render: (text, record) => <span>{record.a24_chg_fdate ? moment(record.a24_chg_fdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'a24_chg_fdate',
      width: 200
    },
    {
      title: 'Trans Code',
      render: (text, record) => <span>{record.d32_trans_code ? TRANS_CODE[record.d32_trans_code] : ''}</span>,
      key: 'd32_trans_code',
      width: 200
    },
    {
      title: 'Comments',
      dataIndex: 'f67_comments',
      key: 'f67_comments',
      width: 400
    },
    {
      title: 'UserId',
      dataIndex: 'f58_userid',
      key: 'f58_userid',
      width: 200
    },
    {
      title: 'Written',
      render: (text, record) => <span>{record.d42_written ? `$${record.d42_written}` : ''}</span>,
      key: 'd42_written',
      width: 200
    },
    {
      title: 'InForce',
      render: (text, record) => <span>{record.d41_inforce ? `$${record.d41_inforce}` : ''}</span>,
      key: 'd41_inforce',
      width: 200
    },
    {
      title: 'Pb Switch',
      dataIndex: 'a0x_pb_switch',
      key: 'a0x_pb_switch',
      width: 200
    },
    {
      title: 'Fees',
      render: (text, record) => <span>{record.d42_fees ? `$${record.d42_fees}` : ''}</span>,
      key: 'd42_fees',
      width: 200
    },
    {
      title: 'Amount Due',
      render: (text, record) => <span>{record.a60_amount_due ? `$${record.a60_amount_due}` : ''}</span>,
      key: 'a60_amount_due',
      width: 200
    },
    {
      title: 'SVC Due',
      render: (text, record) => <span>{record.a63_svc_due ? `$${record.a63_svc_due}` : ''}</span>,
      key: 'a63_svc_due',
      width: 200
    },
    {
      title: 'Prev Amt Due',
      render: (text, record) => <span>{record.b06_prev_amt_due ? `$${record.b06_prev_amt_due}` : ''}</span>,
      key: 'b06_prev_amt_due',
      width: 200
    },
    {
      title: 'Cash',
      render: (text, record) => <span>{record.b01_cash ? `$${record.b01_cash}` : ''}</span>,
      key: 'b01_cash',
      width: 200
    },
    {
      title: 'Total Cash',
      render: (text, record) => <span>{record.a62_total_cash ? `$${record.a62_total_cash}` : ''}</span>,
      key: 'a62_total_cash',
      width: 200
    },
    {
      title: 'Carry Date',
      render: (text, record) => <span>{record.a58_carry_date ? moment(record.a58_carry_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'a58_carry_date',
      width: 200
    },
    {
      title: 'Write Off',
      render: (text, record) => <span>{record.e40_write_off ? `$${record.e40_write_off}` : ''}</span>,
      key: 'e40_write_off',
      width: 200
    },
    {
      title: 'Charge Off',
      render: (text, record) => <span>{record.e41_charge_off ? `$${record.e41_charge_off}` : ''}</span>,
      key: 'e41_charge_off',
      width: 200
    },
    {
      title: 'Refund',
      render: (text, record) => <span>{record.b18_refund ? `$${record.b18_refund}` : ''}</span>,
      key: 'b18_refund',
      width: 200
    },
    {
      title: 'Check No.',
      dataIndex: 'd62_check_no',
      key: 'd62_check_no',
      width: 200
    },
    {
      title: 'Check Date',
      render: (text, record) => <span>{record.d61_check_date ? moment(record.d61_check_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'd61_check_date',
      width: 200
    },
    {
      title: 'Bill Form1',
      dataIndex: 'b00_bill_form1',
      key: 'b00_bill_form1',
      width: 200
    },
    {
      title: 'Due Date',
      render: (text, record) => <span>{record.a59_due_date ? moment(record.a59_due_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</span>,
      key: 'a59_due_date',
      width: 200
    },
    {
      title: 'Dollars4',
      render: (text, record) => <span>{record.x22_dollars4 ? `$${record.x22_dollars4}` : ''}</span>,
      key: 'x22_dollars4',
      width: 200
    },
    {
      title: 'Dollars6',
      render: (text, record) => <span>{record.x22_dollars6 ? `$${record.x22_dollars6}` : ''}</span>,
      key: 'x22_dollars6',
      width: 200
    }
  ]

  return (
    <Table
      columns={columns}
      scroll={{
        x: 4600
      }}
      pagination={false}
      dataSource={termTransactionHistory}
    />
  )
}

TransactionsInfo.propTypes = {}

TransactionsInfo.defaultProps = {}

export default TransactionsInfo
